import styled from 'styled-components';

interface StyledSignInProps {
  isButtonStyle?: boolean;
}

export const SignInLink = styled.a<StyledSignInProps>`
  color: var(--way-colors-contrastColorShades-70);
  font-family: 'Open Sans', sans-serif;
  text-decoration: none;
  transition: color 0.3s ease;
  cursor: pointer;

  &:hover {
    color: var(--way-colors-contrastColorShades-100);
  }

  ${({ isButtonStyle }) =>
    isButtonStyle &&
    `
    border-radius: var(--way-design-borderRadiusDefault);
    border: 0.5px solid var(--way-colors-borderColor);
    padding: 15px 40px;
  `}
`;

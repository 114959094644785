import { z } from 'zod';
import { Currency, StripeSupportedCountryCodes } from '@kouto/types';
import { useAppState } from 'AppProvider';
import { getRoundingOptions } from 'utils';
import { useBrandCurrency } from './useBrandCurrency';

const LOCALES: Record<StripeSupportedCountryCodes, string> = {
  AE: 'ar-AE', // United Arab Emirates
  AG: 'en-AG', // Antigua and Barbuda
  AL: 'sq-AL', // Albania
  AM: 'hy-AM', // Armenia
  AO: 'pt-AO', // Angola
  AR: 'es-AR', // Argentina
  AT: 'de-AT', // Austria
  AU: 'en-AU', // Australia
  AZ: 'az-AZ', // Azerbaijan
  BA: 'bs-BA', // Bosnia and Herzegovina
  BD: 'bn-BD', // Bangladesh
  BE: 'nl-BE', // Belgium
  BG: 'bg-BG', // Bulgaria
  BH: 'ar-BH', // Bahrain
  BJ: 'fr-BJ', // Benin
  BN: 'ms-BN', // Brunei
  BO: 'es-BO', // Bolivia
  BR: 'pt-BR', // Brazil
  BS: 'en-BS', // Bahamas
  BT: 'dz-BT', // Bhutan
  BW: 'en-BW', // Botswana
  CA: 'en-CA', // Canada
  CH: 'de-CH', // Switzerland
  CI: 'fr-CI', // Côte d'Ivoire
  CL: 'es-CL', // Chile
  CO: 'es-CO', // Colombia
  CR: 'es-CR', // Costa Rica
  CY: 'el-CY', // Cyprus
  CZ: 'cs-CZ', // Czech Republic
  DE: 'de-DE', // Germany
  DK: 'da-DK', // Denmark
  DO: 'es-DO', // Dominican Republic
  DZ: 'ar-DZ', // Algeria
  EC: 'es-EC', // Ecuador
  EE: 'et-EE', // Estonia
  EG: 'ar-EG', // Egypt
  ES: 'es-ES', // Spain
  ET: 'am-ET', // Ethiopia
  FI: 'fi-FI', // Finland
  FR: 'fr-FR', // France
  GA: 'fr-GA', // Gabon
  GB: 'en-GB', // United Kingdom
  GH: 'en-GH', // Ghana
  GI: 'en-GI', // Gibraltar
  GM: 'en-GM', // Gambia
  GR: 'el-GR', // Greece
  GT: 'es-GT', // Guatemala
  GY: 'en-GY', // Guyana
  HK: 'zh-HK', // Hong Kong
  HR: 'hr-HR', // Croatia
  HU: 'hu-HU', // Hungary
  ID: 'id-ID', // Indonesia
  IE: 'en-IE', // Ireland
  IL: 'he-IL', // Israel
  IN: 'hi-IN', // India
  IS: 'is-IS', // Iceland
  IT: 'it-IT', // Italy
  JM: 'en-JM', // Jamaica
  JO: 'ar-JO', // Jordan
  JP: 'ja-JP', // Japan
  KE: 'en-KE', // Kenya
  KH: 'km-KH', // Cambodia
  KR: 'ko-KR', // South Korea
  KW: 'ar-KW', // Kuwait
  KZ: 'kk-KZ', // Kazakhstan
  LA: 'lo-LA', // Laos
  LC: 'en-LC', // Saint Lucia
  LI: 'de-LI', // Liechtenstein
  LK: 'si-LK', // Sri Lanka
  LT: 'lt-LT', // Lithuania
  LU: 'fr-LU', // Luxembourg
  LV: 'lv-LV', // Latvia
  MA: 'ar-MA', // Morocco
  MC: 'fr-MC', // Monaco
  MD: 'ro-MD', // Moldova
  MG: 'mg-MG', // Madagascar
  MK: 'mk-MK', // North Macedonia
  MN: 'mn-MN', // Mongolia
  MO: 'zh-MO', // Macau
  MT: 'mt-MT', // Malta
  MU: 'en-MU', // Mauritius
  MX: 'es-MX', // Mexico
  MY: 'ms-MY', // Malaysia
  MZ: 'pt-MZ', // Mozambique
  NA: 'en-NA', // Namibia
  NE: 'fr-NE', // Niger
  NG: 'en-NG', // Nigeria
  NL: 'nl-NL', // Netherlands
  NO: 'no-NO', // Norway
  NZ: 'en-NZ', // New Zealand
  OM: 'ar-OM', // Oman
  PA: 'es-PA', // Panama
  PE: 'es-PE', // Peru
  PH: 'fil-PH', // Philippines
  PK: 'ur-PK', // Pakistan
  PL: 'pl-PL', // Poland
  PT: 'pt-PT', // Portugal
  PY: 'es-PY', // Paraguay
  QA: 'ar-QA', // Qatar
  RO: 'ro-RO', // Romania
  RS: 'sr-RS', // Serbia
  RW: 'rw-RW', // Rwanda
  SA: 'ar-SA', // Saudi Arabia
  SE: 'sv-SE', // Sweden
  SG: 'en-SG', // Singapore
  SI: 'sl-SI', // Slovenia
  SK: 'sk-SK', // Slovakia
  SM: 'it-SM', // San Marino
  SN: 'fr-SN', // Senegal
  SV: 'es-SV', // El Salvador
  TH: 'th-TH', // Thailand
  TN: 'ar-TN', // Tunisia
  TR: 'tr-TR', // Turkey
  TT: 'en-TT', // Trinidad and Tobago
  TW: 'zh-TW', // Taiwan
  TZ: 'sw-TZ', // Tanzania
  US: 'en-US', // United States
  UY: 'es-UY', // Uruguay
  UZ: 'uz-UZ', // Uzbekistan
  VN: 'vi-VN', // Vietnam
  ZA: 'en-ZA', // South Africa
};

export const useCurrencyFormatter = () => {
  const { settings } = useAppState((app: Record<string, unknown>) => app.brand);
  const currency = useBrandCurrency();

  const format = (amount: number, options?: Intl.NumberFormatOptions) => {
    const countryCode = z
      .nativeEnum(StripeSupportedCountryCodes)
      .safeParse(settings.countryCode);

    const locale = countryCode.success ? LOCALES[countryCode.data] : LOCALES.US;

    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency?.code || Currency.USD,
      currencySign: 'accounting',
      ...(options ?? {}),
    }).format(amount);
  };

  const formattedNumber = (amount: number) => {
    const formattedNumber = format(Number(amount), {
      style: 'decimal',
      ...getRoundingOptions(currency),
    });

    return formattedNumber;
  };

  return {
    currency,
    format,
    formattedNumber,
  };
};

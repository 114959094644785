import { createSlice } from '@reduxjs/toolkit';
import { RewardProgramIntegrationData } from '../types/rewardProgramIntegration';

interface RewardProgramState {
  rewardProgramIntegration: RewardProgramIntegrationData | null;
  loading: boolean;
  error: string | null;
}

const initialState: RewardProgramState = {
  rewardProgramIntegration: null,
  loading: false,
  error: null,
};

export const rewardProgramIntegrationSlice = createSlice({
  name: 'rewardProgramIntegration',
  initialState,
  reducers: {
    setMemberInfo: (state, action) => {
      return {
        ...state,
        rewardProgramIntegration: {
          ...state.rewardProgramIntegration,
          firstName: action.payload.firstName,
          loyaltyId: action.payload.loyaltyId,
          memberSince: action.payload.memberSince,
          points: action.payload.points,
          tierCode: action.payload.tierCode,
          tierExpiration: action.payload.tierExpiration,
        },
      };
    },
  },
});

export const { setMemberInfo } = rewardProgramIntegrationSlice.actions;

export default rewardProgramIntegrationSlice.reducer;

import { AVAILABLE_BRAND_FEATURES } from '@kouto/types';
import { useAppState } from 'AppProvider';

const useIsHyattLMSEnabledFlag = () => {
  const { brand } = useAppState();
  const { settings } = brand || {};
  const hyattLMSEnabled =
    settings?.features?.active.includes(
      AVAILABLE_BRAND_FEATURES.ENABLE_HYATT_LMS,
    ) ?? false;
  return hyattLMSEnabled;
};

export default useIsHyattLMSEnabledFlag;

import moment from 'moment';
import debounce from 'lodash/debounce';
import flow from 'lodash/fp/flow';
import head from 'lodash/fp/head';
import concat from 'lodash/fp/concat';
import join from 'lodash/fp/join';
import pick from 'lodash/pick';
import replace from 'lodash/fp/replace';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import { CURRENCY_CODES } from 'types/brand';
import { NON_DECIMAL_CURRENCIES, getCurrencyCode } from '@kouto/types';
import isNil from 'lodash/isNil';
import { PaymentMethod } from 'types/payment';
import parsePhoneNumber from 'libphonenumber-js';
import themePalette from 'theme/themePalette';

export const getRoundingOptions = (currency) => {
  const currencyCode = getCurrencyCode({ code: currency.code });

  if (NON_DECIMAL_CURRENCIES.includes(currencyCode)) {
    return {
      roundingMode: 'halfCeil',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    };
  }

  return {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
};

export const currencyFormat = (currency) => (n) => {
  if (!currency || typeof currency?.currencySymbol === 'undefined') {
    return n;
  }

  const { currencySymbol } = currency;

  if (isNil(n)) return '--';

  const roundingOptions = getRoundingOptions(currency);

  const amount = n.toLocaleString('en', roundingOptions);
  if (currencySymbol === 'CAD') {
    return `${amount} ${currencySymbol}`;
  }

  if (currencySymbol === 'CHF' || currencySymbol === 'AED') {
    return `${currencySymbol} ${amount}`;
  }

  return `${currencySymbol}${amount}`;
};

export const TIME_FORMAT = 'HH:mm:ss';

export const TIME_FORMAT_FOR_DISPLAY = 'h:mm a';

export const DATE_FORMAT = 'YYYY-MM-DD';

export const DATE_FORMAT_FOR_DISPLAY = 'MMM DD, YYYY';

export const ISO_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const formatDateForReq = (date, formatType = DATE_FORMAT) => {
  return moment(date).format(formatType);
};
export const formatDateForMonth = (date) => {
  return moment(date).format('MMM D');
};

export const formatDateForDisplay = (date) => {
  return moment(date).format(DATE_FORMAT_FOR_DISPLAY);
};

export const formatTimeInAMPM = (time, format) => {
  return moment(time, format ?? TIME_FORMAT).format(TIME_FORMAT_FOR_DISPLAY);
};

export const parseZoneDate = (date, format = DATE_FORMAT_FOR_DISPLAY) => {
  return moment.parseZone(date).format(format);
};

export const addMinutesToDate = (addition, format) => (sessionDate) => {
  return moment(sessionDate, format ?? undefined).add('minutes', addition);
};

export const isBeforeDate = (scheduleDate) => (sessionDateTime) => {
  return moment(sessionDateTime).isSameOrBefore(scheduleDate);
};

export const isAfterDate = (scheduleDate) => (sessionDate) => {
  return moment(scheduleDate).isSameOrAfter(sessionDate);
};

/**
 * Calculate UTC offset hours based on timezoneId
 * like: -06:00 / +02:00
 */
export const getTimezoneOffset = (timezoneId) => {
  const now = new Date();
  const tzString = now.toLocaleString('en-US', { timeZone: timezoneId });
  const localString = now.toLocaleString('en-US');
  const diff = (Date.parse(localString) - Date.parse(tzString)) / 3600000;
  const offset = -(diff + now.getTimezoneOffset() / 60);
  const minutes =
    Number((Math.abs(offset) - Math.abs(Math.trunc(offset))).toFixed(2)) * 60;

  const offsetMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const offsetHour =
    offset < 10 ? `0${Math.trunc(offset)}` : Math.trunc(offset);

  if (offset <= 0) {
    return `-0${Math.abs(offset)}:${offsetMinutes}`;
  }
  return `+${offsetHour}:${offsetMinutes}`;
};

export const formatUTCDate = (sessionDate, sessionTime) => {
  return moment.utc(`${sessionDate} ${sessionTime}`).format();
};

/**
 * Convert object to query string
 */
export function serializeParams(obj) {
  const filteredObj = Object.keys(obj).reduce((prev, key) => {
    if (typeof obj[key] === 'undefined' || obj[key] === null) {
      return prev;
    }

    return {
      ...prev,
      [key]: obj[key],
    };
  }, {});

  return `?${new URLSearchParams(filteredObj).toString()}`;
}

const sanitizeUUIDIds = (key, value) => {
  if (key !== 'categoryIds' && key !== 'vibeIds') return value;
  const decodedValue = decodeURIComponent(value ?? '')
    .replace(/"/g, '')
    .split('&')[0];

  return decodedValue;
};
/**
 * Convert query string to object
 */
export function queryParamsToObject(queryString) {
  const params = new URLSearchParams(queryString);

  return [...params.entries()].reduce((prev, [key, value]) => {
    const decodedValue = decodeURIComponent(sanitizeUUIDIds(key, value));
    return {
      ...prev,
      [key]:
        decodedValue.split(',').length > 1
          ? decodedValue.split(',')
          : sanitizeUUIDIds(key, value),
    };
  }, {});
}

export const debounceInput = (func, time) => {
  return debounce(func, time || 300);
};

export const getRandomColor = () => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
  return `#${randomColor}`;
};

// extract last letter
// if ends in y -> add ies. otherwise just add s.
// return plural name
export function getPluralName(singularName) {
  const getLastLetter = (noun) => noun.slice(-1);

  const replacWithPlural = (noun) => {
    const lastLetter = getLastLetter(noun);
    if (lastLetter === 'y') return noun.replace(/y$/, 'ies');
    if (lastLetter === 's') return noun;
    return `${noun}s`;
  };

  return replacWithPlural(singularName);
}

export const getSessionDuration = (sessionDurationMinutes) => {
  if (!sessionDurationMinutes) return null;
  const hours = parseInt(sessionDurationMinutes / 60, 10);

  const minutes = sessionDurationMinutes % 60;
  const hourDisplay = `${hours}h`;
  const minuteDisplay = `${minutes}m`;
  if (hours === 0) {
    return minuteDisplay;
  }
  if (minutes === 0) {
    return hourDisplay;
  }
  return `${hourDisplay} ${minuteDisplay}`;
};

export const rgbaToHex = (rgba) => {
  const [r, g, b] = rgba.match(/\d+/g);
  return `#${Number(r).toString(16).padStart(2, '0')}${Number(g)
    .toString(16)
    .padStart(2, '0')}${Number(b).toString(16).padStart(2, '0')}`;
};

// Need to Work For Later Used TODO
const hexToRgb = (hex) => {
  // turn hex val to RGB
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    (hex || '').substring(0, 7),
  );
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const getContrastShades = (colorHex) => {
  const rgb = hexToRgb(colorHex);
  if (!rgb) return themePalette.black;

  const lum = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;

  return lum > 0.5 ? themePalette.black : themePalette.white;
};

// calc to work out if it will match on black or white better
const setContrast = (rgb, alphaShade = 100) => {
  if (!rgb) return '#000';
  const lum = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;
  return lum > 0.5
    ? `var(--way-palette-black-${alphaShade})`
    : `var(--way-palette-white-${alphaShade})`;
};

export const getCorrectColor = (colorHex, alphaShade = 100) => {
  return setContrast(hexToRgb(colorHex), alphaShade);
};

export const getAlphaColor = (hex, alpha) => {
  const rgb = hexToRgb(hex);
  if (!rgb) return 'var(--way-palette-black-50)';
  return `rgba(${rgb.r},${rgb.g},${rgb.b}, ${alpha})`;
};

// Short Hand Ellipsis
export const getShortDescription = (description, length) => {
  if (!description) return null;
  if (description.length <= length) {
    return description;
  }
  return `${description.substring(0, length - 2)}...`;
};

// appeand Style
export const appendExternalStyle = (href) => {
  const linkTag = document.createElement('LINK');
  linkTag.rel = 'stylesheet';
  linkTag.href = href;
  document.head.appendChild(linkTag);
};

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// Lighten Color
export const brightenColor = (col, amt, isDarkMode) => {
  if (isDarkMode || !col) return 'var(--way-palette-black-90)';
  /* eslint no-bitwise: "off" */
  let usePound = false;
  let color = col;
  if (color[0] === '#') {
    color = color.slice(1);
    usePound = true;
  }

  const num = parseInt(color.substring(0, 6), 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

const concatBaseUrl = (baseUrl) => concat(baseUrl);

const sanitizeUrl = replace(/\/\//, '/');

const formBaseUrl = flow(head, concatBaseUrl);

export const getSanitizedPathName = (params, pathname) => {
  return flow(formBaseUrl(params), join(''), sanitizeUrl)(pathname);
};

export const isImagePortrait = (width, height) => {
  return height > width;
};

export const isImageSquare = (width, height) => {
  return width === height;
};

export const isImageWideAngle = (width, height) => {
  return width / 2 > height;
};

export const isImageRatioGreaterOne = (width, height) => {
  return height / width > 1;
};

export const isImageRatioGreaterSixteenNine = (width, height) => {
  return height / width > 0.56;
};

export function roundToDecimal(num, digits) {
  return Number.parseFloat(num).toFixed(digits || 1);
}

export const curriedRoundToDecimal = (digits) => (num) => {
  return Number.parseFloat(num).toFixed(digits || 1);
};

export function getCurrencyFromCode(currencyCode) {
  return CURRENCY_CODES[currencyCode];
}

export function formatCurrency(currencyCode, amount) {
  return currencyFormat(getCurrencyFromCode(currencyCode))(amount);
}

export const getUserFullName = (user) => {
  if (!user) return '';
  return `${user.firstName} ${user.lastName}`;
};

export function removeTimeZonePart(dateString) {
  const positive = dateString.split('+');
  if (positive.length > 1) {
    return positive[0];
  }
  const negative = dateString.split('-');
  if (negative.length > 3) {
    return negative.slice(0, negative.length - 1).join('-');
  }
  const withZ = dateString.split('Z');
  if (withZ.length > 1) {
    return withZ[0];
  }
  return dateString;
}

export function getRandomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const isPhoneNumberValid = (value) => {
  if (typeof value !== 'string') {
    return false;
  }

  const parsed = parsePhoneNumber(value);

  if (parsed === undefined) {
    return false;
  }

  return parsed.isValid();
};

export const isParagraphPresent = (htmlText) => {
  return /<p\s*.*>\s*.*<\/p>/.test(htmlText);
};

export const isOrderedListPresent = (htmlText) => {
  return /<ol\s*.*>\s*.*<\/ol>/.test(htmlText);
};

export const isUnorderedListPresent = (htmlText) => {
  return /<ul\s*.*>\s*.*<\/ul>/.test(htmlText);
};

export const isExperienceMultipleTiers = (brandExperience) =>
  brandExperience?.priceTiers?.length > 1;

export const isSessionMultipleTiers = (tiers) =>
  Array.isArray(tiers) && tiers.length > 1;

export const isExperienceComplimentary = (brandExperience) => {
  return brandExperience?.priceTiers
    ?.map((priceCategory) => +priceCategory.price)
    .every((expAmount) => expAmount === 0);
};

export const isSessionComplimentary = (tier) => {
  return tier
    ?.map((priceCategory) => +priceCategory.price)
    .every((amount) => amount === 0);
};

export const getExperienceStarterPrice = (brandExperience) => {
  const experiencePricingTiers = [
    ...(brandExperience?.priceTiers || []),
    ...(brandExperience?.conditionalPriceTiers || []),
  ];
  return experiencePricingTiers
    .filter((priceCategory) => Number(priceCategory?.price))
    .sort((a, b) => a.price - b.price, 0)?.[0];
};

export const getSessionStarterPrice = (session) => {
  const sessionPricingTiers = session?.priceTiers || [];
  return sessionPricingTiers
    .filter((priceCategory) => Number(priceCategory?.price))
    .sort((a, b) => a.price - b.price, 0)?.[0];
};

export const getMultipleSessionsStarterPrice = (sessions) => {
  const pricingTiers = sessions?.map((session) => session.priceTiers).flat();
  return pricingTiers
    .filter((priceCategory) => Number(priceCategory?.price))
    .sort((a, b) => a.price - b.price, 0)?.[0];
};

export const formatLink = (urlValue) => {
  if (urlValue.startsWith('http://') || urlValue.startsWith('https://')) {
    return urlValue;
  }
  return `https://${urlValue}`;
};

export const getPercentageAmount = (percentageValue) => (totalAmount) => {
  return totalAmount * (percentageValue / 100);
};

export const parseTextFromHtmlString = (htmlString) => {
  return htmlString.replace(/<\/?[^>]+>/gi, '');
};

export const getCurrencyFormattedPrice = (currencyCode, price) => {
  const currency = getCurrencyFromCode(currencyCode);
  return `${currencyFormat(currency)(Number(price))}`;
};

export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  );
};

export const isCreditCharge = (option) => {
  return option === PaymentMethod.CREDIT_CARD;
};

export const isRoomCharge = (option) => {
  return option === PaymentMethod.ROOM_CHARGE;
};

export const isMemberCharge = (option) => {
  return option === PaymentMethod.MEMBER_NUMBER;
};

export const isKICCCharge = (option) => {
  return option === PaymentMethod.KICC;
};

export const isIntegrationChargeOption = (option) => {
  return (
    option === PaymentMethod.CREDIT_CARD || option === PaymentMethod.ROOM_CHARGE
  );
};

export const sleep = (sec) => {
  return new Promise((resolve) => {
    setTimeout(resolve, sec * 1000);
  });
};

const MOBILE_BREAKPOINT = 767;
export const getDeviceType = () => {
  const isMobile =
    /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ||
    window.innerWidth <= MOBILE_BREAKPOINT ||
    window.matchMedia(`(max-width: ${MOBILE_BREAKPOINT}px)`).matches;

  return isMobile ? 'mobile' : 'pc';
};

export const filterExperienceSearchParams = (searchParams) => {
  const experiencesFilterParam = pick(searchParams, [
    'categoryIds',
    'vibeIds',
    'startDate',
    'endDate',
    'lang',
    'debug',
  ]);

  const filterParams = omitBy(experiencesFilterParam, (value) =>
    isEmpty(value),
  );
  return new URLSearchParams(filterParams).toString();
};

export function decodeHTML(text) {
  const parser = new DOMParser();
  const decodedString = parser.parseFromString(text, 'text/html').body
    .textContent;
  return decodedString || '';
}

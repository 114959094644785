import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useHyattAuth from './useHyattAuth';
import useIsHyattLMSEnabledFlag from './useIsHyattLMSEnabledFlag';

const useHyattAuthenticateUser = () => {
  const location = useLocation();
  const { checkForAuthCode, postAuthenticate, verifySession } = useHyattAuth();
  const hyattLMSEnabled = useIsHyattLMSEnabledFlag();

  useEffect(() => {
    const isSessionAcive = verifySession();
    if (isSessionAcive) return;
    const code = checkForAuthCode();
    if (!code) return;
    postAuthenticate(code);
  }, [location.search, hyattLMSEnabled]);
};

export default useHyattAuthenticateUser;

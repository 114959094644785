import React, { useState, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import i18n from 'i18next';
import moment from 'moment';
import Select from 'react-select';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { NavigationHeader } from 'components/NavigationHeader';
import { PrimaryButton } from 'components/theme/Button';
import {
  ANALYTICS_EVENT,
  ANALYTICS_PROPERTY,
  AVAILABLE_BRAND_FEATURES,
} from '@kouto/types';

import { formatListingMedia } from 'utils/listings';
import ReviewsList from 'components/ReviewsList';
import CheckBox from 'components/theme/CheckBox/CheckBox';
import { Wrapper } from 'components/theme/Wrapper';
import PictureModal from 'components/PictureModal';
import ModalGallery from 'components/ModalGallery';
import SingleDatePicker from 'components/DatePicker';
import ToggleFeature from 'components/ToggleFeature';
import ErrorBoundary from 'components/ErrorBoundary';
import { SkeletonLine } from 'components/theme/Skeleton';
import BlockDescription from 'components/BlockDescription';
import { Error, ErrorContainer } from 'components/theme/Form';
import ResponsiveImage from 'components/common/ResponsiveImage';
import AvailabilityCalendar from 'components/AvailabilityCalendar';
import useOneListing from 'hooks/useOneListing';

import { BOOKING_MODES } from 'types/booking';
import { TIER_PARTICIPANTS } from 'types/participants';
import { validateAccessCode } from 'actions/access-code';
import useSelectedParticipants from 'selectors/participants';
import { useAppState, useBrandId, useDispatch } from 'AppProvider';
import {
  getAvailableDates,
  getAvailableSessions,
  getFirstAvailableDate,
  resetFirstAvailableDate,
} from 'actions/experience';
import {
  setTierParticipants,
  integrateSessionParticipants,
} from 'actions/participants';
import useCartItems from 'hooks/useCartItems';
import useCancellationPolicy from 'hooks/useCancellationPolicy';
import {
  getSessionDuration,
  formatDateForReq,
  isImageRatioGreaterOne,
  serializeParams,
  isSessionMultipleTiers,
  formatLink,
  formatUTCDate,
} from 'utils';

import { useIsMobile } from 'WindowDimensionProvider';
import useCustomHistory from 'hooks/use-custom-history';
import useSearchQueryParams from 'hooks/use-search-params';
import useBrandToggleFeature from 'components/BrandToggleFeature/use-brand-toggle-feature';
import { useIsNarrowView } from 'ContainerDimensionProvider';
import { VALIDATE_ACCESS_CODE_FAILURE } from 'types/access-code';
import { useTierParticipants } from 'hooks/use-selected-participants';
import {
  STATUS_FAILURE,
  STATUS_IDLE,
  STATUS_PENDING,
  STATUS_SUCCESS,
} from 'types/app';
import {
  BOOKING_AVAILABILITY_MODE_PRIVATE,
  BOOKING_AVAILABILITY_MODE_NON_RESTRICTED,
  TYPE_ONE_TIME,
  TYPE_RECURRING,
  EXTERNAL_CTA_LINK,
  EXTERNAL_CTA_EMAIL,
  EXTERNAL_CTA_PHONE,
} from 'types/experience';
import useAvailableSeats from 'components/ExperienceView/hooks/useAvailableSeats';
import { useCart } from 'hooks/useCart';

import CalendarIcon from 'assets/calendar';
import PeopleIcon from 'assets/icon-user';
import LocationIcon from 'assets/icon-pin';
import ExpDurationIcon from 'assets/icon-clock';
import PrevArrowIcon from 'assets/chevron-left';
import NextArrowIcon from 'assets/chevron-right';
import OpenMenuIcon from 'assets/chevron-down';
import ResponsiveLayout from 'ResponsiveLayout';
import { usePageViewEvent } from 'hooks/usePageViewEvent';
import { IsBookableMapper } from 'features/analytics/services/analytics.helpers';
import { usePriceTierDataFromSelectedParticipants } from 'features/analytics/hooks/usePriceTierAnalyticsDataCollection';
import { useExperienceAnalyticsData } from 'features/analytics/hooks/useExperienceAnalyticsData';
import { RadioButtons } from 'components/ExperienceView/sub-components/RadioButtons';
import { formatDuration } from 'utils/formatDuration';
import { TruncatedText } from 'components/TruncatedText';
import MediaCarousel from 'components/Carousel/MediaCarousel';

import { SrOnly } from 'components/common/styled/common-styled';
import ChipList from 'components/ChipList';
import Chip from 'components/Chip';
import DateView from './sub-components/DateView';
import PartySize from './sub-components/PartySize';
import ParticipantsDropdown from './sub-components/ParticipantsDropdown';
import EventMeta from './sub-components/EventMeta';
import RadioWrapperItem from './sub-components/RadioWrapper';

import * as SC from './styles';
import { selectStyles, dropdownBtnStyles, selectTheme } from './styles';
import { ExperienceLocation } from './ExperienceLocation';
import ExperienceCancellationPolicy from './ExperienceCancellationPolicy';
import SessionPrice from './sub-components/SessionPrice';
import useStartTimes from './hooks/useStartTimes';
import useCartExperienceSession from './hooks/useCartExperienceSession';
import { ExperienceDescImage } from './sub-components/ExperinceDescImage/ExperienceDescImage';

/* eslint react/prop-types: 'off' */
const CustomSelectValue = (props) => (
  <SC.CustomSelectDiv>
    <SC.IconBackSelect className="experience-view__icon-back-select">
      <PeopleIcon data-testid="people-icon" />
    </SC.IconBackSelect>
    {props.data.label}
  </SC.CustomSelectDiv>
);

const ErrorMessage = ({ errors, name, message }) => {
  if (errors[name]) {
    return (
      <Error id={`${name}-error`} role="alert" aria-live="assertive">
        {message}
      </Error>
    );
  }
  return null;
};

ErrorMessage.propTypes = {
  errors: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

const SingleDatePickerFormWrapper = ({
  date,
  onChange,
  isDayHighlighted,
  isMobile,
  isOutsideRange,
  onPrevMonthClick,
  onNextMonthClick,
  initialVisibleMonth,
  setCalendarLoading,
}) => {
  const { t } = useTranslation();
  return (
    <SingleDatePicker
      date={date}
      onChange={onChange}
      isDayHighlighted={isDayHighlighted}
      displayFormat="MMM D"
      orientation={isMobile ? 'vertical' : 'horizontal'}
      transitionDuration={isMobile ? 0 : 300}
      verticalHeight={350}
      isOutsideRange={isOutsideRange}
      onPrevMonthClick={onPrevMonthClick}
      onNextMonthClick={onNextMonthClick}
      initialVisibleMonth={initialVisibleMonth}
      readOnly
      navPrev={
        <SC.PrevIconCalendar
          tabIndex={0}
          onClick={() => setCalendarLoading(true)}
          role="button"
        >
          <PrevArrowIcon />
        </SC.PrevIconCalendar>
      }
      navNext={
        <SC.NextIconCalendar
          tabIndex={0}
          onClick={() => setCalendarLoading(true)}
          role="button"
        >
          <NextArrowIcon />
        </SC.NextIconCalendar>
      }
      id="kouto_selectedSessionDateAvailabilityCalendarSelector"
      customInputIcon={
        <>
          <SC.IconBack>
            <CalendarIcon role="presentation" />
          </SC.IconBack>
          <SrOnly>{t('calendarIcon')}</SrOnly>
        </>
      }
      inputIconPosition="before"
    />
  );
};

SingleDatePickerFormWrapper.propTypes = {
  date: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  isDayHighlighted: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isOutsideRange: PropTypes.func.isRequired,
  initialVisibleMonth: PropTypes.func.isRequired,
};

SingleDatePickerFormWrapper.defaultProps = {
  date: null,
};

const SelectWrapper = (props) => {
  const { t: translate } = useTranslation();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);

  const handleKeyDown = (event) => {
    if (menuIsOpen) {
      if (event.key === 'Tab') {
        event.preventDefault();
        setFocusedOptionIndex((prevIndex) => {
          const nextIndex =
            prevIndex !== null ? (prevIndex + 1) % props.options.length : 0;
          return nextIndex;
        });
      } else if (event.key === 'Enter' && focusedOptionIndex !== null) {
        event.preventDefault();
        handleSelectOption();
      }
    } else if (event.key === 'Enter') {
      event.preventDefault();
      setMenuIsOpen(true);
    }
  };

  const handleSelectOption = () => {
    setMenuIsOpen(false);
    setFocusedOptionIndex(null);
  };

  return (
    <SC.SelectBox aria-label={translate('selectParticipants')}>
      <Select
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        onKeyDown={handleKeyDown}
        onBlur={() => setMenuIsOpen(false)}
        tabIndex={0}
        aria-label={translate('selectParticipants')}
        label="Guests"
        styles={selectStyles}
        theme={selectTheme}
        isSearchable={false}
        {...props}
        components={{
          SingleValue: CustomSelectValue,
        }}
      />
    </SC.SelectBox>
  );
};

const ExperienceView = () => {
  const isNarrowView = useIsNarrowView();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [isSquare, setIsSquare] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const { searchParams } = useSearchQueryParams();
  const { t: translate } = useTranslation();
  const [accessCodeNumber, setAccessCodeNumber] = useState('');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const { onAddToCart } = useCart();

  const [, setSelectedTierCustomers] = useTierParticipants();

  const [participantValidationMessage, setParticipantValidationMessage] =
    useState(null);

  const onImgLoad = ({ target: img }) => {
    setIsSquare(isImageRatioGreaterOne(img.naturalWidth, img.naturalHeight));
  };
  const brandId = useBrandId();
  const availabilityCalendarPrevButton = useRef(null);
  const availabilityCalendarNextButton = useRef(null);
  const { push: customPush } = useCustomHistory();
  const {
    handleSubmit,
    control,
    errors,
    getValues,
    setValue: setSidebarFormValue,
    watch,
  } = useForm();

  const {
    availabilities: availableDates,
    sessions: availableSessions,
    firstAvailableDate,
    fetchFirstAvailabilityStatus,
    searchAvailabilityStatus,
    experience,
    fetchExperienceStatus,
    fetchSessionStatus,
  } = useAppState((state) => state.experience);
  const { listing } = useOneListing(experience?.loading ? '' : experience?.id);
  const { cancellationPolicy, isLoading: isLoadingCancellationPolicy } =
    useCancellationPolicy(experience.cancellationPolicyId);

  const durations = useMemo(() => {
    const durationsSet = new Set();
    return availableSessions
      .reduce((acc, session) => {
        const { duration } = session;
        if (duration && !durationsSet.has(duration)) {
          durationsSet.add(duration);
          acc.push({
            value: duration,
            label: formatDuration(duration),
            durationInMinutes: moment.duration(duration, 'minutes'),
          });
        }

        return acc;
      }, [])
      .sort((a, b) => a.durationInMinutes - b.durationInMinutes);
  }, [availableSessions]);

  const { currentExperienceDetail } = useExperienceAnalyticsData();

  usePageViewEvent({
    eventName: ANALYTICS_EVENT.VIEW_EXPERIENCE_DETAIL,
    payload: {
      [ANALYTICS_PROPERTY.ExperienceId]: currentExperienceDetail?.id,
      [ANALYTICS_PROPERTY.ExperienceTitle]: currentExperienceDetail?.title,
    },
    optionalPayload: {
      [ANALYTICS_PROPERTY.IsBookable]:
        IsBookableMapper[
          (currentExperienceDetail?.isBookable ?? '').toString()
        ],
      [ANALYTICS_PROPERTY.ExperienceCategory]:
        currentExperienceDetail?.category?.name,
    },
    isNotReady: !currentExperienceDetail?.id,
  });

  const { cartItems } = useCartItems();

  const { isBookable, type } = experience;

  const isRecurring = isEqual(type, TYPE_RECURRING);
  const isOneTime = isEqual(type, TYPE_ONE_TIME);
  const isHostLed = !experience?.isNoHost;

  const isCartFeatureEnabled = useBrandToggleFeature('shoppingCart');

  const { error: validateAccessCodeError, validateAccessCodeStatus } =
    useAppState((state) => state.accessCode);

  const {
    selectedParticipants,
    selectedSession,
    totalSelected: totalSelectedParticipants,
    currentExperience,
  } = useSelectedParticipants();

  const [singlePriceTierCount, setSinglePriceTierCount] = useState(1);

  const getPriceTierExperienceSelectedTiers = () => {
    if (experience.loading) return [];

    if (experience.priceTiers.length > 1) return selectedParticipants;

    return [
      {
        ...experience.priceTiers[0],
        selectedNumber: singlePriceTierCount,
      },
    ];
  };

  usePriceTierDataFromSelectedParticipants({
    title: experience.title || '',
    category: experience.category?.name,
    tiers: getPriceTierExperienceSelectedTiers(),
  });

  const isWithinCuttoff = selectedSession?.withinOnlineBookingCutoffWindow;

  const [showModal, setShowModal] = useState(false);
  const [isPrivateMode, setIsPrivateMode] = useState(false);
  const [selectedEventSession, setSelectedEventSession] = useState(null);
  const availablePriceTiers = selectedEventSession?.priceTiers || [];

  // initial date range should always be kept null at first
  // because the Availability calendar once rendered on a date doesn't change
  // month when changing the dateRange state.
  const [dateRange, setDateRange] = useState({
    startDateCalendar: null,
    endDateCalendar: null,
  });
  const [
    selectedSessionDateAvailabilityCalendar,
    setSelectedSessionDateAvailabilityCalendar,
  ] = useState(moment(searchParams.sessionDate) ?? null);
  const { startDateCalendar, endDateCalendar } = dateRange;
  const [codeIsRequiredMsg, setCodeIsRequiredMsg] = useState(null);
  const [calendarLoading, setCalendarLoading] = useState(false);

  const userProfileImage = get(experience, 'hostedBy.profilePicture.uri');
  const experienceDescImage = experience.descriptionPicture?.uri;
  const EMAIL_SUBJECT = `${experience?.title} Inquiry`;
  const isExperienceFetchSuccess = fetchExperienceStatus === STATUS_SUCCESS;

  const guests = watch('noOfGuests');
  const formattedDate = formatDateForReq(
    selectedSessionDateAvailabilityCalendar,
  );

  const availableSeatsForSession = useAvailableSeats(
    selectedEventSession,
    searchParams.sessionDate ?? formattedDate,
    experience.id,
  );

  const [selectedDuration, setSelectedDuration] = useState(durations[0]?.value);
  const [selectedStartTime, setSelectedStartTime] = useState(null);

  const { experienceStartTimes, isUnavailable } = useStartTimes({
    selectedGuests: isSessionMultipleTiers(availablePriceTiers)
      ? totalSelectedParticipants
      : guests?.value,
    selectedDate: searchParams.sessionDate ?? formattedDate,
    availablePriceTiers: availablePriceTiers[0] ? [availablePriceTiers[0]] : [],
    selectedDuration,
    filterDisabled: true,
  });

  const isSoldOut = useMemo(() => {
    if (selectedSession) {
      return selectedSession?.supportedParticipantsCount === 0;
    }
    return (
      availableSessions.length &&
      availableSessions.every((sess) => sess.supportedParticipantsCount === 0)
    );
  }, [selectedSession, availableSessions]);

  const cartExperience = useCartExperienceSession(
    selectedEventSession,
    searchParams.sessionDate ?? formattedDate,
  );

  const dispatchAvailableSessionsHelper = (date, query) => {
    if (experience?.loading) {
      return;
    }
    dispatch(
      getAvailableSessions(experience.id, formatDateForReq(date), {
        ...query,
        latest: String(searchParams.latest).toLowerCase() === 'true',
        cartExperienceList: cartItems,
      }),
    );
  };

  const getAvailableDatesHelper = ({
    from,
    to,
    isPrivate,
    shouldAppend,
    latest,
  }) => {
    if (experience?.loading) {
      return;
    }
    dispatch(
      getAvailableDates(
        experience,
        {
          from: formatDateForReq(from),
          to: formatDateForReq(to),
          isPrivate,
          latest,
        },
        shouldAppend,
      ),
    );
  };

  useEffect(() => {
    if (validateAccessCodeError) {
      setAccessCodeNumber('');
    }
  }, [validateAccessCodeError]);

  useEffect(() => {
    if (!isExperienceFetchSuccess) return;
    setSidebarFormValue(
      'sessionDate',
      searchParams.sessionDate ? moment(searchParams.sessionDate) : moment(),
    );
    const startSearchDate =
      searchParams?.startDate && searchParams?.endDate
        ? moment(searchParams.startDate).startOf('month')
        : moment();

    let endSearchDate =
      searchParams?.startDate && searchParams?.endDate
        ? moment(searchParams.endDate).endOf('month')
        : moment().add(1, 'month');

    if (endSearchDate.isSame(startSearchDate, 'month')) {
      endSearchDate = startSearchDate.clone().add(1, 'month').endOf('month');
    }
    if (searchParams?.sessionDate) {
      setDateRange({
        startDateCalendar: moment(searchParams.sessionDate).startOf('month'),
        endDateCalendar: moment(searchParams.sessionDate)
          .endOf('month')
          .add(1, 'month'),
      });
      dispatchAvailableSessionsHelper(moment(searchParams.sessionDate), {
        isPrivate: searchParams?.mode === BOOKING_MODES.PRIVATE,
        latest: String(searchParams.latest).toLowerCase() === 'true',
      });
      getAvailableDatesHelper({
        from: moment(searchParams.sessionDate).startOf('month'),
        to: moment(searchParams.sessionDate).endOf('month').add(1, 'month'),
        isPrivate: isPrivateMode,
        shouldAppend: false,
        latest: String(searchParams.latest).toLowerCase() === 'true',
      });
      return;
    }
    dispatch(
      getFirstAvailableDate(experience.id, {
        from: searchParams?.startDate
          ? formatDateForReq(searchParams.startDate)
          : formatDateForReq(moment()),
        isPrivate: isPrivateMode,
        latest: String(searchParams.latest).toLowerCase() === 'true',
      }),
    );
    if (searchParams?.accessCode) {
      setAccessCodeNumber(searchParams.accessCode);
    }
    /* eslint-disable consistent-return */
    return function cleanup() {
      dispatch(resetFirstAvailableDate());
    };
  }, [experience.id]);

  const handlePrivateCheckboxChange = () => {
    const isPrivate = !isPrivateMode;
    setIsPrivateMode(!isPrivateMode);
    getAvailableDatesHelper({
      from: startDateCalendar,
      to: endDateCalendar,
      isPrivate,
      shouldAppend: true,
      latest: String(searchParams.latest).toLowerCase() === 'true',
    });

    dispatchAvailableSessionsHelper(selectedSessionDateAvailabilityCalendar, {
      isPrivate,
      latest: String(searchParams.latest).toLowerCase() === 'true',
    });

    dispatch(
      getFirstAvailableDate(experience.id, {
        from: searchParams?.startDate
          ? formatDateForReq(searchParams.startDate)
          : formatDateForReq(moment()),
        isPrivate,
        latest: String(searchParams.latest).toLowerCase() === 'true',
      }),
    );
  };

  const handleBooking = async (data) => {
    setCodeIsRequiredMsg(null);
    if (
      isSessionMultipleTiers(availablePriceTiers) &&
      totalSelectedParticipants < 1
    ) {
      setParticipantValidationMessage(translate('selectParticipantValidation'));
      return;
    }

    if (!data) return;
    const { noOfGuests, sessionDate, sessionTime, isPrivateSession } = data;
    if (noOfGuests) {
      dispatch(
        setTierParticipants({
          participants: availablePriceTiers,
          currentExperience: experience.id,
        }),
      );

      const selectedData = {
        ...selectedSession?.priceTiers[0],
        ...{ selectedNumber: noOfGuests.value },
      };

      setSelectedTierCustomers({
        selectedParticipants: [selectedData],
        currentExperience,
      });
    }
    const formattedSessionDate = formatDateForReq(
      isOneTime ? availableDates[0] : sessionDate,
    );

    const newSessionTime = isOneTime
      ? availableSessions[0]?.startTime
      : sessionTime;

    if (experience.isExclusive) {
      if (!accessCodeNumber) {
        setCodeIsRequiredMsg('Exclusive Code Is Required.');
        return;
      }
      const validateResponse = await dispatch(
        validateAccessCode({
          code: accessCodeNumber,
          brandId,
          experienceId: experience.id,
          isCartFeatureEnabled,
          sessionDateTime: formatUTCDate(formattedSessionDate, newSessionTime),
        }),
      );
      if (validateResponse.type === VALIDATE_ACCESS_CODE_FAILURE) {
        setCodeIsRequiredMsg('Invalid Code');
        return;
      }
    }

    // if (experience.isExclusive) {
    //   bookingData.accessCode = accessCodeNumber;
    // }

    const bookingMode =
      isPrivateSession ||
      experience?.bookingAvailabilityMode === BOOKING_AVAILABILITY_MODE_PRIVATE
        ? BOOKING_MODES.PRIVATE
        : BOOKING_MODES.SHARED;
    const cartData = {
      participants: (isSessionMultipleTiers(availablePriceTiers)
        ? selectedParticipants
        : [
            {
              ...selectedSession?.priceTiers[0],
              ...{ selectedNumber: noOfGuests.value },
            },
          ]
      )
        .map((p) => new Array(p.selectedNumber).fill(p))
        .flat()
        .map((p) => ({
          fullName: '',
          firstName: '',
          lastName: '',
          terms: false,
          price: p.price,
          priceTierName: p.name,
        })),
      notes: '',
      state: 'PENDING',
      bookingMode,
      listingId: experience.id,
      experienceId: experience?.id,
      additionalCustomQuestionResponses: {},
      sessionDateTime: `${
        formattedDate ?? formattedSessionDate
      }T${newSessionTime}`,
      sessionDuration: selectedDuration,
      // TODO - to fix the private booking total price bug*, we can just add maxParticipantCountForPrivateBooking.
      // But we need to fix this bug also in the backend.
      // * (totalPrice = ticketPrice * listing.defaultParticipantsCount instead of selectedSession.supportedParticipantsCount)
      //
      // maxParticipantCountForPrivateBooking:
      //   bookingMode === BOOKING_MODES.PRIVATE
      //     ? selectedSession.supportedParticipantsCount
      //     : undefined,
    };

    const params = new URLSearchParams();
    if (String(searchParams.latest).toLowerCase() === 'true') {
      params.set('latest', 'true');
    }

    if (listing?.addOns?.length) {
      onAddToCart(cartData, `/addons/{cartItemId}`, params.toString());
    } else {
      onAddToCart(
        cartData,
        `/e/${experience.slug}/${
          isCartFeatureEnabled ? 'participants' : 'booking'
        }/{cartItemId}`,
        params.toString(),
      );
    }
  };

  const isNoSeatsAvailableForSession =
    !isSessionMultipleTiers(availablePriceTiers) &&
    availableSeatsForSession?.length === 0;

  const containsRemindersText =
    !!experience?.healthAndSafety &&
    experience?.healthAndSafety !== '<p><br></p>';

  const displayAccessCodeInput = () => {
    return (
      <SC.AccessCodeContainer containshealthtext={containsRemindersText}>
        <SC.AccessTitle>
          Please enter the code you received in order to reserve this experience
        </SC.AccessTitle>
        <SC.AccessInputContainer>
          <SC.AccessInput
            type="text"
            size="large"
            name="accessCode"
            placeholder="Access code"
            value={accessCodeNumber}
            onChange={(e) => {
              setAccessCodeNumber(e.target.value);
            }}
          />
          <SC.AccessButton
            type="submit"
            disabled={
              validateAccessCodeStatus === STATUS_PENDING ||
              availableDates.length < 1 ||
              isSoldOut ||
              isWithinCuttoff ||
              !selectedStartTime ||
              isNoSeatsAvailableForSession
            }
          >
            {translate('book')}
          </SC.AccessButton>
        </SC.AccessInputContainer>
        <SC.CodeIsRequired>{codeIsRequiredMsg}</SC.CodeIsRequired>
        <SC.ValidationMessageBlock>
          <ErrorMessage
            errors={errors}
            name="sessionTime"
            message={translate('selectSessionValidation')}
          />
        </SC.ValidationMessageBlock>
      </SC.AccessCodeContainer>
    );
  };

  const isDayHighlighted = (date) => {
    return (
      !calendarLoading &&
      availableDates?.find((availabilityDate) => {
        return moment(availabilityDate).isSame(date, 'day');
      })
    );
  };

  const onNextMonthClick = (todayDate) => {
    let fromDate = todayDate;
    if (todayDate.isAfter(moment(), 'day')) {
      fromDate = todayDate.clone().startOf('month');
    }
    getAvailableDatesHelper({
      from: fromDate,
      to: todayDate.clone().add(1, 'month').endOf('month'),
      isPrivate: isPrivateMode,
      shouldAppend: true,
      latest: String(searchParams.latest).toLowerCase() === 'true',
    });
    setCalendarLoading(false);
  };

  const onPrevMonthClick = (todayDate) => {
    let fromDate = todayDate;
    if (todayDate.isAfter(moment(), 'day')) {
      fromDate = todayDate.clone().startOf('month');
    }
    getAvailableDatesHelper({
      from: fromDate,
      to: todayDate.clone().add(1, 'month').endOf('month'),
      isPrivate: isPrivateMode,
      shouldAppend: true,
      latest: String(searchParams.latest).toLowerCase() === 'true',
    });
    setCalendarLoading(false);
  };

  const onSessionDatePrevMonthClick = (todayDate) => {
    /* eslint no-unused-expressions: 'off' */
    // This next line would preselect the first day of the new month, even if no sessions are available on that day.
    // availabilityCalendarPrevButton.current?.click();
    let fromDate = todayDate;
    if (todayDate.isAfter(moment(), 'day')) {
      fromDate = todayDate.clone().startOf('month');
    }
    const toDate = todayDate.clone().add(1, 'month').endOf('month');
    setDateRange({
      startDateCalendar: fromDate,
      endDateCalendar: toDate,
    });
    getAvailableDatesHelper({
      from: fromDate,
      to: toDate,
      isPrivate: isPrivateMode,
      shouldAppend: true,
      latest: String(searchParams.latest).toLowerCase() === 'true',
    });
    setCalendarLoading(false);
  };

  const onSessionDateNextMonthClick = (todayDate) => {
    /* eslint no-unused-expressions: 'off' */
    // This next line would preselect the first day of the new month, even if no sessions are available on that day.
    // availabilityCalendarNextButton.current?.click();
    let fromDate = todayDate;
    if (todayDate.isAfter(moment(), 'day')) {
      fromDate = todayDate.clone().startOf('month');
    }
    const toDate = todayDate.clone().add(1, 'month').endOf('month');
    setDateRange({
      startDateCalendar: fromDate,
      endDateCalendar: toDate,
    });
    getAvailableDatesHelper({
      from: fromDate,
      to: toDate,
      isPrivate: isPrivateMode,
      shouldAppend: true,
      latest: String(searchParams.latest).toLowerCase() === 'true',
    });
    setCalendarLoading(false);
  };

  const onPrevDateDate = () => {
    const fromDate = moment(startDateCalendar)
      .clone()
      .subtract(2, 'month')
      .startOf('month');
    const toDate = fromDate.clone().add(1, 'month').endOf('month');
    setDateRange({
      startDateCalendar: fromDate,
      endDateCalendar: toDate,
    });

    getAvailableDatesHelper({
      from: fromDate,
      to: toDate,
      isPrivate: isPrivateMode,
      shouldAppend: true,
      latest: String(searchParams.latest).toLowerCase() === 'true',
    });
  };

  const onNextDateRange = () => {
    const fromDate = moment(startDateCalendar)
      .clone()
      .add(2, 'month')
      .startOf('month');
    const toDate = fromDate.clone().add(1, 'month').endOf('month');
    setDateRange({
      startDateCalendar: fromDate,
      endDateCalendar: toDate,
    });

    getAvailableDatesHelper({
      from: fromDate,
      to: toDate,
      isPrivate: isPrivateMode,
      shouldAppend: true,
      latest: String(searchParams.latest).toLowerCase() === 'true',
    });
  };

  const onSessionDateSelect = (date) => {
    setSelectedSessionDateAvailabilityCalendar(date);
  };

  useEffect(() => {
    if (isSessionMultipleTiers(availablePriceTiers) && currentExperience) {
      setSelectedTierCustomers({
        selectedParticipants,
        currentExperience,
      });
    }
  }, [selectedParticipants]);

  const hostName = useMemo(() => {
    const host = experience.hostedBy;
    if (!host?.firstName || !host?.lastName) {
      return 'Host';
    }

    return `${host.firstName ?? ''} ${host.lastName ?? ''}`.trim();
  }, [experience?.hostedBy]);

  useEffect(() => {
    if (fetchSessionStatus === STATUS_PENDING || !selectedEventSession) return;

    const priceTiers = availablePriceTiers ?? availableSessions[0]?.priceTiers;
    const noOfGuests = getValues('noOfGuests');
    if (isSessionMultipleTiers(priceTiers)) {
      const countableParticipants = priceTiers?.map((priceLabel) => ({
        ...priceLabel,
        selectedNumber: 0,
      }));
      dispatch(
        setTierParticipants({
          participants: countableParticipants,
          currentExperience: experience.id,
        }),
      );
      return;
    }
    if (noOfGuests?.value > availableSeatsForSession?.length) {
      setSidebarFormValue('noOfGuests', { label: 1, value: 1 });
    }
  }, [experience, availableSessions, selectedEventSession, fetchSessionStatus]);

  useEffect(() => {
    dispatch(
      integrateSessionParticipants({
        session: availableSessions[0],
      }),
    );
  }, [availableSessions, experience]);

  useEffect(() => {
    if (fetchSessionStatus === STATUS_PENDING) {
      return;
    }
    const validSession = availableSessions?.find((sess) => {
      return (
        sess?.startTime === selectedStartTime &&
        sess.duration === selectedDuration
      );
    });

    setSelectedEventSession(validSession);
    dispatch(integrateSessionParticipants({ session: validSession }));
  }, [
    selectedStartTime,
    selectedDuration,
    availableSessions,
    fetchSessionStatus,
  ]);

  useEffect(() => {
    if (selectedSessionDateAvailabilityCalendar) {
      dispatchAvailableSessionsHelper(selectedSessionDateAvailabilityCalendar, {
        isPrivate: isPrivateMode,
        latest: String(searchParams.latest).toLowerCase() === 'true',
      });
    }
    setSidebarFormValue('sessionTime', null);
  }, [selectedSessionDateAvailabilityCalendar]);

  useEffect(() => {
    if (firstAvailableDate) {
      setSelectedSessionDateAvailabilityCalendar(moment(firstAvailableDate));
      setSidebarFormValue('sessionDate', moment(firstAvailableDate));
      setDateRange({
        startDateCalendar: moment(firstAvailableDate).startOf('month'),
        endDateCalendar: moment(firstAvailableDate)
          .endOf('month')
          .add(1, 'month'),
      });
      let fromDate = moment(firstAvailableDate).startOf('month');
      if (fromDate.isSame(moment(), 'month')) {
        fromDate = moment();
      }
      getAvailableDatesHelper({
        from: fromDate,
        to: moment(firstAvailableDate).endOf('month').add(1, 'month'),
        isPrivate: isPrivateMode,
        shouldAppend: true,
        latest: String(searchParams.latest).toLowerCase() === 'true',
      });
    }
    if (
      (fetchFirstAvailabilityStatus === STATUS_SUCCESS ||
        fetchFirstAvailabilityStatus === STATUS_FAILURE) &&
      !firstAvailableDate
    ) {
      setDateRange({
        startDateCalendar: moment().startOf('month'),
        endDateCalendar: moment().endOf('month').add(1, 'month'),
      });
    }
  }, [firstAvailableDate, fetchFirstAvailabilityStatus]);

  useEffect(() => {
    const isValidSelectedStartTime = experienceStartTimes?.find((session) => {
      return (
        !session.isDisabled &&
        session.duration === selectedDuration &&
        session.startTime === selectedStartTime
      );
    });
    if (isValidSelectedStartTime) {
      /* If duration is changed, but a valid selectedStartTime corresponds correctly to that duration */
      /* then keep the selectedStartTime state as it is, dont update */
      return;
    }

    /*
      Give default state to selectedStartTime if it is null,
      Or if a session startTime doesn't exist for the selected duration,
      reset it with the first available valid startTime
    */
    const firstValidSession = experienceStartTimes?.find((session) => {
      return !session.isDisabled && session.duration === selectedDuration;
    });

    setSelectedStartTime(firstValidSession?.startTime || null);
    setSidebarFormValue('sessionTime', firstValidSession?.startTime);
  }, [selectedDuration, experienceStartTimes]);

  const getFormattedLocationName = (location) => {
    const commaSeparated = location?.name.split(',');
    return (
      commaSeparated
        ?.slice(-2)
        .join(',')
        .replace(/[0-9]+/, '') ?? ''
    );
  };

  const filterSearchParams = (params) => {
    return omit(params, ['noOfGuests', 'sessionDate', 'mode']);
  };

  const onModalClosure = () => {
    setIsViewOpen(false);
    setShowModal(false);
  };

  const onLinkToPreviousPage = () => {
    if (searchParams?.previous === '/calendar') {
      customPush({
        pathname: `/calendar`,
        search: serializeParams(filterSearchParams(searchParams)),
      });
      return;
    }
    customPush({
      pathname: `/experiences`,
      search: serializeParams(filterSearchParams(searchParams)),
    });
  };

  const redirectingCtaExternal = () => {
    const externalCtaValue = experience?.externalField?.value;
    const externalCtaType = experience?.externalField?.type;
    if (isEqual(externalCtaType, EXTERNAL_CTA_EMAIL)) {
      return `mailto:${externalCtaValue}?subject=${EMAIL_SUBJECT}`;
    }
    if (isEqual(externalCtaType, EXTERNAL_CTA_PHONE)) {
      return `tel:${externalCtaValue}`;
    }
    return formatLink(externalCtaValue);
  };

  const isExternalTypeLink = () =>
    isEqual(experience?.externalField?.type, EXTERNAL_CTA_LINK);

  const onDropdownToggle = (e) => {
    if (isDropdownVisible && !isEqual(e.target.id, 'noOfGuests')) {
      setIsDropdownVisible(false);
    }
  };

  const onTierDropdownClick = (e) => {
    e.stopPropagation();
  };

  const isFetchingSessionTiers =
    (fetchSessionStatus === STATUS_IDLE ||
      fetchSessionStatus === STATUS_PENDING) &&
    fetchFirstAvailabilityStatus !== STATUS_FAILURE;

  useEffect(() => {
    if (durations.length) {
      setSelectedDuration(durations[0]?.value);
    }
  }, [durations]);

  const isFlexibleDurationEnabled = useBrandToggleFeature(
    AVAILABLE_BRAND_FEATURES.FLEXIBLE_DURATION,
  );

  const carouselMedias = formatListingMedia(experience?.medias).map(
    (media, index) => ({
      ...media,
      alt: `${experience.title} photo ${index + 1}`,
    }),
  );

  /* eslint jsx-a11y/label-has-associated-control: 'off' */
  return (
    <div
      style={isViewOpen ? { overflow: 'hidden', height: '0' } : null}
      id="experience-view"
      key={i18n.language}
    >
      <EventMeta {...experience} slug={experience.slug || ''} />
      {isDropdownVisible ? (
        <SC.TierOverlay
          className="experience-view__tier-overlay"
          onClick={() => setIsDropdownVisible(false)}
        />
      ) : null}
      <Wrapper>
        {String(searchParams?.latest).toLowerCase() !== 'true' && (
          <NavigationHeader
            onGoBack={onLinkToPreviousPage}
            preventDefaultBack
            className="experience-view__experience-navigation"
          />
        )}
        <SC.ExperiencesWrapper>
          <SC.ExperiencesHeader>
            <ErrorBoundary>
              <SC.ExperiencesHeaderContent loading={experience.loading}>
                {!experience.loading ? (
                  <SC.MainTitles as="h1">{experience.title}</SC.MainTitles>
                ) : (
                  <SkeletonLine translucent />
                )}
                {!experience.loading ? (
                  <SC.Headline>{experience.headline}</SC.Headline>
                ) : (
                  <SkeletonLine translucent />
                )}
                <SC.TagButtonWrap
                  className="experience-view__tag-buttons-container"
                  aria-label="Experience tags"
                >
                  {!experience.loading ? (
                    <ChipList className="experience-view__category-button-container">
                      <SC.CategoryChip
                        as="li"
                        className="experience-view__category-button"
                      >
                        {get(experience, 'category.name')}
                      </SC.CategoryChip>
                      {experience?.vibes?.map(({ name }) => (
                        <Chip
                          as="li"
                          key={name}
                          className="experience-view__vibe"
                        >
                          {name}
                        </Chip>
                      ))}
                    </ChipList>
                  ) : (
                    <SkeletonLine translucent />
                  )}
                </SC.TagButtonWrap>
                {!isFlexibleDurationEnabled &&
                  (!experience.loading ? (
                    <SC.ExperiencesHeaderContentMeta className="experience-view__experience-header-content-meta">
                      <SC.ExperiencesHeaderContentList className="experience-view__experience-meta__duration">
                        <SC.DurationWrap>
                          <ExpDurationIcon />
                        </SC.DurationWrap>
                        <SC.ListLabel>{translate('duration')}</SC.ListLabel>
                        <SC.ListValue>
                          {getSessionDuration(
                            experience.sessionDurationMinutes,
                          )}
                        </SC.ListValue>
                      </SC.ExperiencesHeaderContentList>
                      {isBookable ? (
                        <SC.ExperiencesHeaderContentList className="experience-view__experience-meta__capacity">
                          <SC.PeopleIconWrap>
                            <PeopleIcon />
                          </SC.PeopleIconWrap>
                          <SC.ListLabel>
                            {isRecurring
                              ? translate('groupSize')
                              : translate('eventSize')}
                          </SC.ListLabel>
                          <SC.ListValue>
                            {translate('upTo')}
                            &nbsp;
                            {experience.maxParticipantsCount}
                          </SC.ListValue>
                        </SC.ExperiencesHeaderContentList>
                      ) : null}
                      <SC.ExperiencesHeaderContentList className="experience-view__experience-meta__location">
                        <SC.LocationIconWrap>
                          <LocationIcon />
                        </SC.LocationIconWrap>
                        <SC.ListLabel>{translate('location')}</SC.ListLabel>
                        <SC.ListValue>
                          {getFormattedLocationName(experience?.location)}
                        </SC.ListValue>
                      </SC.ExperiencesHeaderContentList>
                    </SC.ExperiencesHeaderContentMeta>
                  ) : (
                    <SkeletonLine translucent />
                  ))}
              </SC.ExperiencesHeaderContent>
            </ErrorBoundary>
            {/* Experience Header */}
            <ErrorBoundary>
              <MediaCarouselWrapper>
                {carouselMedias.length > 0 && (
                  <MediaCarousel medias={carouselMedias} fit="contain" />
                )}
              </MediaCarouselWrapper>
            </ErrorBoundary>
          </SC.ExperiencesHeader>
          {/* Start of Body */}
          <SC.ExperiencesBody>
            <SC.ExperiencesBodyContent loading={experience.loading}>
              {!experience.loading ? (
                <SC.Title4
                  as="h2"
                  className="experience-view__experience-section-title"
                >
                  {translate('details')}
                </SC.Title4>
              ) : (
                <SkeletonLine translucent />
              )}
              {!experience.loading ? (
                <SC.Padding8>
                  <TruncatedText
                    text={experience.description}
                    isRichText
                    mode="inline"
                  />
                </SC.Padding8>
              ) : (
                <SkeletonLine translucent />
              )}
              {experience.loading ? (
                <SkeletonLine translucent />
              ) : (
                !!experienceDescImage && (
                  <SC.Padding8>
                    <ExperienceDescImage
                      uriObject={experienceDescImage}
                      altText={experience.title}
                    />
                  </SC.Padding8>
                )
              )}

              {isHostLed ? (
                <SC.ExperiencesBodyHost>
                  {!experience.loading ? (
                    <SC.Title4
                      as="h3"
                      className="experience-view__experience-section-title"
                    >
                      {translate('hostedBy')}{' '}
                      {get(experience, 'hostedBy.firstName')}
                    </SC.Title4>
                  ) : (
                    <SkeletonLine translucent />
                  )}
                  <SC.HostedBy>
                    {userProfileImage ? (
                      <SC.HostedByImageWrap>
                        {!experience.loading ? (
                          <ResponsiveImage
                            uriObject={userProfileImage}
                            defaultSize="48w"
                            isSquare={isSquare}
                            onLoad={onImgLoad}
                            CustomRenderer={SC.HostedByImage}
                            alt={translate('photoOf', { name: hostName })}
                          />
                        ) : (
                          <SkeletonLine translucent />
                        )}
                      </SC.HostedByImageWrap>
                    ) : (
                      (!experience.loading && (
                        <SC.DefaultUserAvatar>
                          <PeopleIcon />
                        </SC.DefaultUserAvatar>
                      )) || <SkeletonLine translucent />
                    )}

                    {!experience.loading ? (
                      <SC.HostDescription>
                        <BlockDescription
                          description={get(experience, 'hostedBy.description')}
                          showReadMore
                          shortDescriptionLength={450}
                        />
                      </SC.HostDescription>
                    ) : (
                      <SC.DescriptionSkeleton>
                        <SkeletonLine translucent />
                      </SC.DescriptionSkeleton>
                    )}
                  </SC.HostedBy>
                </SC.ExperiencesBodyHost>
              ) : null}

              {experience?.providedItems?.length ? (
                <SC.ExperienceInclude>
                  {!experience.loading ? (
                    <SC.Title4
                      as="h3"
                      className="experience-view__experience-section-title"
                    >
                      {translate('whatsIncluded')}
                    </SC.Title4>
                  ) : (
                    <SkeletonLine translucent />
                  )}
                  <SC.ExperienceIncludeListWrap>
                    {!experience.loading ? (
                      <SC.ExperienceIncludeList>
                        {experience.providedItems.map((providedItem) => (
                          <SC.ListButton
                            className="experience-view__reminder-item"
                            key={`providedItem-${providedItem}`}
                          >
                            {providedItem}
                          </SC.ListButton>
                        ))}
                      </SC.ExperienceIncludeList>
                    ) : (
                      <SkeletonLine translucent />
                    )}
                  </SC.ExperienceIncludeListWrap>
                </SC.ExperienceInclude>
              ) : null}
              <ErrorBoundary>
                <ResponsiveLayout
                  breakPoint={767}
                  renderDesktop={() =>
                    isBookable && isRecurring ? (
                      <SC.ExperiencesAvailability>
                        {!experience.loading ? (
                          <div>
                            <SC.Title4
                              as="h3"
                              className="experience-view__experience-section-title"
                            >
                              {translate('avail')}
                            </SC.Title4>
                            {startDateCalendar ? (
                              <AvailabilityCalendar
                                date={startDateCalendar}
                                onChange={(date) => {
                                  setSidebarFormValue('sessionDate', date);
                                  onSessionDateSelect(date);
                                }}
                                isLoading={
                                  searchAvailabilityStatus === STATUS_PENDING
                                }
                                isDayHighlighted={isDayHighlighted}
                                isMobile={isMobile || isNarrowView}
                                orientation={
                                  isMobile || isNarrowView
                                    ? 'vertical'
                                    : 'horizontal'
                                }
                                numberOfMonths={2}
                                keepOpenOnDateSelect
                                isOutsideRange={(date) =>
                                  !isDayHighlighted(date)
                                }
                                onPrevMonthClick={onPrevMonthClick}
                                onNextMonthClick={onNextMonthClick}
                                navPrev={
                                  <SC.PrevIconCalendar
                                    ref={availabilityCalendarPrevButton}
                                    onClick={() => setCalendarLoading(true)}
                                    tabIndex={0}
                                    role="button"
                                  >
                                    <PrevArrowIcon width="30" />
                                  </SC.PrevIconCalendar>
                                }
                                navNext={
                                  <SC.NextIconCalendar
                                    ref={availabilityCalendarNextButton}
                                    onClick={() => setCalendarLoading(true)}
                                    tabIndex={0}
                                    role="button"
                                  >
                                    <NextArrowIcon width="27" />
                                  </SC.NextIconCalendar>
                                }
                              />
                            ) : (
                              <SkeletonLine translucent />
                            )}
                          </div>
                        ) : (
                          <SkeletonLine translucent />
                        )}
                      </SC.ExperiencesAvailability>
                    ) : null
                  }
                  renderMobile={() => null}
                />
              </ErrorBoundary>
              <ExperienceLocation
                className="experience-view__experience-section-title"
                loading={experience.loading}
                location={experience.location}
              />
              <ExperienceCancellationPolicy
                className="experience-view__experience-section-title"
                cancellationPolicy={cancellationPolicy}
                isLoading={isLoadingCancellationPolicy}
              />
              <ErrorBoundary>
                <ToggleFeature featureId="reviews">
                  <ReviewsList
                    {...experience}
                    loading={experience.loading ?? false}
                  />
                </ToggleFeature>
              </ErrorBoundary>
            </SC.ExperiencesBodyContent>

            <ErrorBoundary>
              {isBookable ? ( // for reservable experiences
                <SC.ExperiencesBodySidebar
                  aria-label={translate('bookExperiencePanel')}
                  className="experience-view__experience-body-sidebar"
                  loading={experience.loading}
                  onClick={onDropdownToggle}
                >
                  {!experience.loading ? (
                    <SessionPrice selectedSession={selectedEventSession} />
                  ) : (
                    <SkeletonLine translucent style={{ height: '23px' }} />
                  )}
                  {!experience.loading ? (
                    (isRecurring && (
                      <SC.SidebarTitle>{experience.title}</SC.SidebarTitle>
                    )) ||
                    null
                  ) : (
                    <SkeletonLine translucent />
                  )}
                  {!experience.loading ? (
                    (isRecurring && <PartySize />) || null
                  ) : (
                    <SkeletonLine translucent />
                  )}
                  {!experience.loading ? (
                    (isRecurring && (
                      <SC.SidebarForm
                        className="experience-view__sidebar-form"
                        onSubmit={handleSubmit(handleBooking)}
                      >
                        {experience.bookingAvailabilityMode ===
                          BOOKING_AVAILABILITY_MODE_NON_RESTRICTED && (
                          <SC.PrivateCheckboxContainer>
                            <Controller
                              control={control}
                              onChange={([selected]) => {
                                handlePrivateCheckboxChange(selected);
                                return selected;
                              }}
                              name="isPrivateSession"
                              valueName="checked"
                              defaultValue={
                                searchParams?.mode
                                  ? searchParams?.mode === BOOKING_MODES.PRIVATE
                                  : isPrivateMode
                              }
                              as={(props) => {
                                return (
                                  <CheckBox
                                    id="isPrivateMode"
                                    label={translate(
                                      'viewPrivateSessAvailability',
                                    )}
                                    {...props}
                                  />
                                );
                              }}
                            />
                          </SC.PrivateCheckboxContainer>
                        )}

                        <SC.AvailabilitySessions>
                          <SC.SingleCalendarWrapper className="experience-view__single-calendar-wrapper">
                            <Controller
                              as={<SingleDatePickerFormWrapper />}
                              control={control}
                              rules={{ required: true }}
                              onChange={([selected]) => {
                                onSessionDateSelect(selected);
                                return selected;
                              }}
                              isDayHighlighted={isDayHighlighted}
                              isOutsideRange={(date) => !isDayHighlighted(date)}
                              isMobile={isMobile}
                              setCalendarLoading={setCalendarLoading}
                              transitionDuration={isMobile ? 0 : 300}
                              onOutsideClick={() => {}}
                              onPrevMonthClick={onSessionDatePrevMonthClick}
                              onNextMonthClick={onSessionDateNextMonthClick}
                              defaultValue={
                                searchParams?.sessionDate
                                  ? moment(searchParams.sessionDate)
                                  : selectedSessionDateAvailabilityCalendar
                              }
                              name="sessionDate"
                              valueName="date"
                              isDropdownOpen={isDropdownVisible}
                              aria-describedby="sessionDate"
                            />
                            <ErrorMessage
                              errors={errors}
                              name="sessionDate"
                              message={translate('selectDateValidation')}
                              ErrorMessage={ErrorMessage}
                              SelectWrapper={SelectWrapper}
                            />
                          </SC.SingleCalendarWrapper>
                          {isFetchingSessionTiers && (
                            <SkeletonLine
                              translucent
                              style={{
                                height: '54px',
                                width: '173px',
                                minWidth: '173px',
                                marginBottom: '0px',
                              }}
                            />
                          )}
                          {!isFetchingSessionTiers && (
                            <label aria-label={translate('selectParticipants')}>
                              {isSessionMultipleTiers(availablePriceTiers) ? (
                                <>
                                  <SrOnly>
                                    {translate('selectParticipants')}
                                  </SrOnly>
                                  <SC.ParticipantWrapper
                                    className="experience-view__participant-wrapper"
                                    tabIndex={0}
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter') {
                                        setIsDropdownVisible(true);
                                      }
                                    }}
                                    onClick={(e) => {
                                      setIsDropdownVisible(true);
                                      e.stopPropagation();
                                    }}
                                    aria-describedby="selectParticipantValidation-error"
                                  >
                                    <SC.WrapperFirst className="experience-view__first-participant-wrapper">
                                      <PeopleIcon />
                                      <span>
                                        {totalSelectedParticipants ||
                                          translate('select')}
                                      </span>
                                    </SC.WrapperFirst>
                                    <SC.WrapperSecond
                                      isDropdownVisible={isDropdownVisible}
                                    >
                                      <div />
                                      <OpenMenuIcon />
                                    </SC.WrapperSecond>
                                  </SC.ParticipantWrapper>
                                </>
                              ) : (
                                <Controller
                                  as={
                                    <SelectWrapper
                                      options={availableSeatsForSession}
                                    />
                                  }
                                  control={control}
                                  rules={{ required: true }}
                                  onChange={([selected]) => {
                                    setSinglePriceTierCount(selected.value);

                                    return {
                                      ...selected,
                                      label: selected.value,
                                    };
                                  }}
                                  defaultValue={
                                    searchParams?.noOfGuests
                                      ? {
                                          label: searchParams.noOfGuests,
                                          value: searchParams.noOfGuests,
                                        }
                                      : { label: 1, value: 1 }
                                  }
                                  name="noOfGuests"
                                  aria-describedby="noOfGuests"
                                />
                              )}
                              <ErrorMessage
                                errors={errors}
                                name="noOfGuests"
                                message="No. of Guests is Required"
                              />
                            </label>
                          )}
                        </SC.AvailabilitySessions>

                        {isDropdownVisible && (
                          <SC.TierDropdown
                            className="experience-view__tier-dropdown"
                            data-dropdownid={TIER_PARTICIPANTS}
                            onClick={onTierDropdownClick}
                          >
                            {availablePriceTiers?.map(
                              (priceCategory, index) => {
                                return (
                                  <ParticipantsDropdown
                                    key={`${priceCategory + index}`}
                                    cartExperienceSession={cartExperience}
                                    priceCategory={priceCategory}
                                    index={index}
                                    setParticipantValidationMessage={
                                      setParticipantValidationMessage
                                    }
                                  />
                                );
                              },
                            )}
                            <PrimaryButton
                              big
                              className="experience-view__book-button"
                              style={dropdownBtnStyles}
                              onClick={(e) => {
                                setIsDropdownVisible(false);
                                e.stopPropagation();
                              }}
                            >
                              <span>{translate('apply')}</span>
                            </PrimaryButton>
                          </SC.TierDropdown>
                        )}

                        {isFlexibleDurationEnabled && durations?.length > 1 && (
                          <SC.SessionSidebarBlock>
                            <SC.SidebarSubtitle>
                              {translate('selectDuration')}:
                            </SC.SidebarSubtitle>
                            {durations.length > 0 ? (
                              <RadioButtons
                                asBtn
                                wrap={false}
                                value={selectedDuration}
                                options={durations}
                                name="sessionDuration"
                                onChange={(v) => setSelectedDuration(v)}
                              />
                            ) : (
                              <SkeletonLine
                                translucent
                                style={{ height: '47px' }}
                              />
                            )}
                          </SC.SessionSidebarBlock>
                        )}

                        <SC.SessionSidebarBlock>
                          <SC.SidebarSubtitle>
                            {durations.length > 0
                              ? translate('selectStartTime')
                              : translate('selectSession')}
                            :
                          </SC.SidebarSubtitle>
                          {experienceStartTimes?.length && !isUnavailable ? (
                            <Controller
                              as={
                                <RadioWrapperItem
                                  sessionTimes={experienceStartTimes}
                                />
                              }
                              control={control}
                              rules={{ required: true }}
                              onChange={([selected]) => {
                                setSelectedStartTime(selected.target.value);
                                return selected;
                              }}
                              name="sessionTime"
                              defaultValue={
                                experienceStartTimes?.find(
                                  (startTime) => !startTime.isDisabled,
                                )?.startTime
                              }
                            />
                          ) : (
                            (isFetchingSessionTiers &&
                              fetchFirstAvailabilityStatus !==
                                STATUS_FAILURE && (
                                <SkeletonLine
                                  translucent
                                  style={{ height: '48px' }}
                                />
                              )) || (
                              <SC.DimmedText>
                                {translate('noAvailability')}
                              </SC.DimmedText>
                            )
                          )}
                        </SC.SessionSidebarBlock>

                        {experience.isExclusive ? (
                          displayAccessCodeInput()
                        ) : (
                          <SC.BookingSpace
                            containshealthtext={containsRemindersText}
                          >
                            <PrimaryButton
                              big
                              className="experience-view__book-button"
                              type="submit"
                              disabled={
                                !experienceStartTimes?.length ||
                                isUnavailable ||
                                isNoSeatsAvailableForSession ||
                                !selectedStartTime
                              }
                              analyticEvent={
                                ANALYTICS_EVENT.RESERVABLE_EXPERIENCE_CLICK_BOOK_BUTTON
                              }
                            >
                              {translate('bookExperience')}
                            </PrimaryButton>
                          </SC.BookingSpace>
                        )}
                        <SC.ValidationMessageBlock>
                          <ErrorMessage
                            errors={errors}
                            name="sessionTime"
                            message={
                              <>
                                <Error style={{ fontSize: '14px' }}>
                                  {translate('selectSessionValidation')}
                                </Error>
                                {isSessionMultipleTiers(availablePriceTiers) &&
                                  totalSelectedParticipants < 1 && (
                                    <ErrorContainer>
                                      <Error
                                        id="selectParticipantValidation-error"
                                        role="alert"
                                        aria-live="assertive"
                                        style={{ fontSize: '14px' }}
                                      >
                                        {translate(
                                          'selectParticipantValidation',
                                        )}
                                      </Error>
                                    </ErrorContainer>
                                  )}
                              </>
                            }
                          />
                          {participantValidationMessage && (
                            <ErrorContainer>
                              <Error
                                id="selectParticipantValidation-error"
                                role="alert"
                                aria-live="assertive"
                                style={{ fontSize: '14px' }}
                              >
                                {participantValidationMessage}
                              </Error>
                            </ErrorContainer>
                          )}
                        </SC.ValidationMessageBlock>
                      </SC.SidebarForm>
                    )) ||
                    null
                  ) : (
                    <SkeletonLine style={{ height: 300 }} translucent />
                  )}
                  {!experience.isLoading ? (
                    (isOneTime && (
                      <SC.SidebarForm
                        className="experience-view__sidebar-form"
                        onSubmit={handleSubmit(handleBooking)}
                      >
                        <DateView
                          type={type}
                          startDateCalendar={startDateCalendar}
                          experience={experience}
                          onPrevDateRange={onPrevDateDate}
                          onNextDateRange={onNextDateRange}
                          availableDates={availableDates}
                        />
                        {!experience.loading ? (
                          <PartySize />
                        ) : (
                          <SkeletonLine translucent />
                        )}

                        {isDropdownVisible && (
                          <SC.OpenTierDropdown
                            data-dropdownid={TIER_PARTICIPANTS}
                            onClick={onTierDropdownClick}
                            isPartySizeEnforced={!!experience?.partySize}
                          >
                            {availablePriceTiers?.map(
                              (priceCategory, index) => {
                                return (
                                  <ParticipantsDropdown
                                    key={`${priceCategory + index}`}
                                    cartExperienceSession={cartExperience}
                                    priceCategory={priceCategory}
                                    index={index}
                                    setParticipantValidationMessage={
                                      setParticipantValidationMessage
                                    }
                                  />
                                );
                              },
                            )}
                            <PrimaryButton
                              big
                              className="experience-view__book-button"
                              style={dropdownBtnStyles}
                              onClick={(e) => {
                                setIsDropdownVisible(false);
                                e.stopPropagation();
                              }}
                            >
                              <span>{translate('apply')}</span>
                            </PrimaryButton>
                          </SC.OpenTierDropdown>
                        )}

                        {!isFetchingSessionTiers &&
                          (isSessionMultipleTiers(availablePriceTiers) ? (
                            <>
                              <SC.ParticipantsEventWrapper
                                tabIndex={0}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    e.stopPropagation();
                                    setIsDropdownVisible(true);
                                  }
                                }}
                                onClick={(e) => {
                                  setIsDropdownVisible(true);
                                  e.stopPropagation();
                                }}
                              >
                                <SC.WrapperFirst>
                                  <PeopleIcon />
                                  <span>
                                    {totalSelectedParticipants ||
                                      translate('select')}
                                  </span>
                                </SC.WrapperFirst>
                                <SC.WrapperSecond
                                  isDropdownVisible={isDropdownVisible}
                                >
                                  <div />
                                  <OpenMenuIcon />
                                </SC.WrapperSecond>
                              </SC.ParticipantsEventWrapper>
                            </>
                          ) : (
                            <SC.ParticipantsBoxContainer>
                              <Controller
                                as={
                                  <SelectWrapper
                                    options={availableSeatsForSession}
                                  />
                                }
                                control={control}
                                rules={{ required: true }}
                                onChange={([selected]) => {
                                  setSinglePriceTierCount(selected.value);

                                  return {
                                    ...selected,
                                    label: selected.value,
                                  };
                                }}
                                defaultValue={
                                  searchParams?.noOfGuests
                                    ? {
                                        label: searchParams.noOfGuests,
                                        value: searchParams.noOfGuests,
                                      }
                                    : { label: 1, value: 1 }
                                }
                                name="noOfGuests"
                              />
                              <ErrorMessage
                                errors={errors}
                                name="noOfGuests"
                                message="No. of Guests is Required"
                              />
                            </SC.ParticipantsBoxContainer>
                          ))}

                        {isWithinCuttoff ? (
                          <SC.CutOffMessage>
                            <SC.DurationIcon />
                            Within online booking cutoff
                          </SC.CutOffMessage>
                        ) : null}
                        {isSoldOut ? (
                          <SC.CutOffMessage>
                            {translate('soldOut')}
                          </SC.CutOffMessage>
                        ) : null}
                        {experience.isExclusive ? (
                          displayAccessCodeInput()
                        ) : (
                          <PrimaryButton
                            big
                            className="experience-view__book-button"
                            type="submit"
                            disabled={
                              availableDates.length < 1 ||
                              isSoldOut ||
                              isWithinCuttoff ||
                              experience?.loading ||
                              availableSeatsForSession?.length === 0
                            }
                            analyticEvent={
                              ANALYTICS_EVENT.RESERVABLE_EXPERIENCE_CLICK_BOOK_BUTTON
                            }
                          >
                            {translate('book')}
                          </PrimaryButton>
                        )}
                        {participantValidationMessage && (
                          <ErrorContainer>
                            <Error>{participantValidationMessage}</Error>
                          </ErrorContainer>
                        )}
                      </SC.SidebarForm>
                    )) ||
                    null
                  ) : (
                    <SkeletonLine style={{ height: 300 }} translucent />
                  )}
                  {containsRemindersText ? (
                    <AdditionalReminderSection experience={experience} />
                  ) : null}
                </SC.ExperiencesBodySidebar>
              ) : (
                // for open experiences
                <SC.ExperiencesBodySidebar>
                  {!experience.loading ? (
                    <SessionPrice selectedSession={selectedEventSession} />
                  ) : (
                    <SkeletonLine translucent style={{ height: '23px' }} />
                  )}
                  {!experience.loading ? (
                    <DateView
                      type={type}
                      startDateCalendar={startDateCalendar}
                      experience={experience}
                      availableDates={availableDates}
                      onPrevDateRange={onPrevDateDate}
                      onNextDateRange={onNextDateRange}
                    />
                  ) : (
                    <SkeletonLine translucent />
                  )}

                  {!experience.loading ? (
                    experience?.externalField?.value && (
                      <SC.ExternalLinkContainer>
                        <SC.ExternalLink
                          href={redirectingCtaExternal()}
                          target={isExternalTypeLink() ? '_blank' : '_self'}
                          rel="noreferrer"
                        >
                          <PrimaryButton
                            big
                            analyticEvent={
                              ANALYTICS_EVENT.OPEN_EXPERIENCE_CLICK_BOOK_BUTTON
                            }
                            analyticData={{
                              [ANALYTICS_PROPERTY.CtaUrl]:
                                experience?.externalField?.value,
                            }}
                            className="experience-view__book-button"
                          >
                            {translate('book')}
                          </PrimaryButton>
                        </SC.ExternalLink>
                      </SC.ExternalLinkContainer>
                    )
                  ) : (
                    <SkeletonLine translucent />
                  )}

                  {!experience.loading ? (
                    (containsRemindersText && (
                      <AdditionalReminderSection experience={experience} />
                    )) ||
                    null
                  ) : (
                    <SkeletonLine translucent />
                  )}
                </SC.ExperiencesBodySidebar>
              )}
            </ErrorBoundary>
          </SC.ExperiencesBody>
        </SC.ExperiencesWrapper>

        {showModal && (
          <PictureModal>
            <ModalGallery closeModal={onModalClosure} experience={experience} />
          </PictureModal>
        )}
      </Wrapper>
    </div>
  );
};

const AdditionalReminderSection = ({ experience }) => {
  const { t } = useTranslation();
  return (
    <SC.AdditionalSection>
      <SC.AdditionalSectionTitle>
        <SC.HeadingAdditional className="experience-view__reminders-title">
          {t('reminders')}
        </SC.HeadingAdditional>
      </SC.AdditionalSectionTitle>
      <SC.AdditionalSectionContent>
        <TruncatedText
          text={experience.healthAndSafety}
          isRichText
          mode="inline"
        />
      </SC.AdditionalSectionContent>
    </SC.AdditionalSection>
  );
};

const MediaCarouselWrapper = styled.div`
  aspect-ratio: 16 / 9;
  min-height: 300px;

  @media (min-width: 768px) {
    max-width: 630px;
    min-height: 350px;
  }

  @media (max-width: 767px) {
    width: 100%;
    min-height: 250px;
  }
`;

export default React.memo(ExperienceView);

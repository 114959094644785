import { ButtonLink } from 'components/theme/Button/Button';
import React, { useEffect, useId, useRef, useState } from 'react';
import styled from 'styled-components';
import useClickOutside from 'utils/useClickOutside';

const SignOutContainer = styled.div`
  display: inline-block;
  position: relative;
`;
const StyledButtonLink = styled(ButtonLink)`
  display: flex;
  flex-direction: column;
`;

const SignOutDropdown = styled.ul.withConfig({
  shouldForwardProp: (prop) => !['isDropDownOpen'].includes(prop),
})<{ isDropDownOpen: boolean }>`
  align-items: center;
  background-color: var(--way-palette-white-100);
  border-radius: var(--way-design-borderRadiusDefault);
  border: 0.5px solid var(--way-colors-borderColor);
  box-shadow: var(--way-design-boxShadow-s);
  color: var(--way-colors-linkColor);
  display: ${({ isDropDownOpen }) => (isDropDownOpen ? 'flex' : 'none')};
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
  justify-content: center;
  list-style: none;
  margin-top: 10px;
  padding-left: 40px;
  padding-right: 40px;
  position: absolute;
  width: max-content;
  z-index: 100;
`;

const SortOption = styled.li.withConfig({
  shouldForwardProp: (prop) => !['isSelected'].includes(prop),
})<{ isSelected?: boolean }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  list-style: none;
  padding-bottom: 16px;
  padding-top: 16px;
`;

interface SignOutProps {
  children: React.ReactNode;
  signOutOptions: { label: string; value: string; action: () => void }[];
}

const SignOut = ({ children, signOutOptions }: SignOutProps) => {
  const [isDropDownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownId = useId();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropDownOpen);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      setIsDropdownOpen(false);
    }
  };

  const onOptionKeyDownHandler =
    (action: () => void) => (e: React.KeyboardEvent) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        setIsDropdownOpen(false);
        action();
      }
    };

  const onClickOutside = () => {
    setIsDropdownOpen(false);
  };

  useClickOutside(dropdownRef, onClickOutside);

  useEffect(() => {
    if (isDropDownOpen && dropdownRef.current) {
      dropdownRef.current.focus();
    }
  }, [isDropDownOpen]);

  return (
    <SignOutContainer ref={dropdownRef}>
      <StyledButtonLink
        aria-controls={dropdownId}
        aria-expanded={isDropDownOpen}
        aria-haspopup="listbox"
        onClick={toggleDropdown}
        type="button"
      >
        {children}
      </StyledButtonLink>
      <SignOutDropdown
        aria-controls={dropdownId}
        isDropDownOpen={isDropDownOpen}
        onKeyDown={handleKeyDown}
        role="listbox"
      >
        {signOutOptions
          .filter((option) => option.value !== '')
          .map((option) => (
            <SortOption
              key={option.value}
              onClick={() => {
                setIsDropdownOpen(false);
                option.action();
              }}
              onKeyDown={onOptionKeyDownHandler(option.action)}
              role="option"
              tabIndex={0}
            >
              {option.label}
            </SortOption>
          ))}
      </SignOutDropdown>
    </SignOutContainer>
  );
};

export default SignOut;

import {
  AVAILABLE_BRAND_FEATURES,
  Currency,
  CurrencySymbol,
} from '@kouto/types';

export const FETCH_SETTINGS_REQUEST = '@brand/fetch-settings-request';
export const FETCH_SETTINGS_SUCCESS = '@brand/fetch-settings-success';
export const FETCH_SETTINGS_FAILURE = '@brand/fetch-settings-failure';

export const FETCH_INTEGRATIONS_REQUEST = '@brand/fetch-integrations-request';
export const FETCH_INTEGRATIONS_SUCCESS = '@brand/fetch-integrations-success';
export const FETCH_INTEGRATIONS_FAILURE = '@brand/fetch-integrations-failure';

export const FETCH_TERMS_REQUEST = '@brand/fetch-terms-request';
export const FETCH_TERMS_SUCCESS = '@brand/fetch-terms-success';
export const FETCH_TERMS_FAILURE = '@brand/fetch-terms-failure';

export const SEARCH_CATEGORY_REQUEST = '@brand/search-category-request';
export const SEARCH_CATEGORY_SUCCESS = '@brand/search-category-success';
export const SEARCH_CATEGORY_FAILURE = '@brand/search-category-failure';

export const SEARCH_VIBES_REQUEST = '@brand/search-vibes-request';
export const SEARCH_VIBES_SUCCESS = '@brand/search-vibes-success';
export const SEARCH_VIBES_FAILURE = '@brand/search-vibes-failure';

export const FETCH_CALENDAR_EVENTS_REQUEST =
  '@experience/fetch-calendar-events-request';
export const FETCH_CALENDAR_EVENTS_SUCCESS =
  '@experience/fetch-calendar-events-success';
export const FETCH_CALENDAR_EVENTS_FAILURE =
  '@experience/fetch-calendar-events-failure';

export const FETCH_CALENDAR_FIRST_AVAILABLE_DATE_REQUEST =
  '@experience/fetch-calendar-first-available-date-request';
export const FETCH_CALENDAR_FIRST_AVAILABLE_DATE_SUCCESS =
  '@experience/fetch-calendar-first-available-date-success';
export const FETCH_CALENDAR_FIRST_AVAILABLE_DATE_FAILURE =
  '@experience/fetch-calendar-first-available-date-failure';

export const THEMES = {
  DEFAULT: 'default',
  DARK: 'dark',
};

type CurrencyObject = {
  code: Currency;
  fullName: string;
  currencySymbol: CurrencySymbol;
};

export const CURRENCY_CODES: Record<Currency, CurrencyObject> = {
  [Currency.AED]: {
    code: Currency.AED,
    fullName: 'United Arab Emirates Dirham',
    currencySymbol: CurrencySymbol.AED,
  },
  [Currency.ALL]: {
    code: Currency.ALL,
    fullName: 'Albanian Lek',
    currencySymbol: CurrencySymbol.ALL,
  },
  [Currency.AMD]: {
    code: Currency.AMD,
    fullName: 'Armenian Dram',
    currencySymbol: CurrencySymbol.AMD,
  },
  [Currency.AOA]: {
    code: Currency.AOA,
    fullName: 'Angolan Kwanza',
    currencySymbol: CurrencySymbol.AOA,
  },
  [Currency.ARS]: {
    code: Currency.ARS,
    fullName: 'Argentine Peso',
    currencySymbol: CurrencySymbol.ARS,
  },
  [Currency.AUD]: {
    code: Currency.AUD,
    fullName: 'Australian Dollar',
    currencySymbol: CurrencySymbol.AUD,
  },
  [Currency.AZN]: {
    code: Currency.AZN,
    fullName: 'Azerbaijani Manat',
    currencySymbol: CurrencySymbol.AZN,
  },
  [Currency.BAM]: {
    code: Currency.BAM,
    fullName: 'Bosnia and Herzegovina Convertible Mark',
    currencySymbol: CurrencySymbol.BAM,
  },
  [Currency.BDT]: {
    code: Currency.BDT,
    fullName: 'Bangladeshi Taka',
    currencySymbol: CurrencySymbol.BDT,
  },
  [Currency.BGN]: {
    code: Currency.BGN,
    fullName: 'Bulgarian Lev',
    currencySymbol: CurrencySymbol.BGN,
  },
  [Currency.BHD]: {
    code: Currency.BHD,
    fullName: 'Bahraini Dinar',
    currencySymbol: CurrencySymbol.BHD,
  },
  [Currency.BND]: {
    code: Currency.BND,
    fullName: 'Brunei Dollar',
    currencySymbol: CurrencySymbol.BND,
  },
  [Currency.BOB]: {
    code: Currency.BOB,
    fullName: 'Bolivian Boliviano',
    currencySymbol: CurrencySymbol.BOB,
  },
  [Currency.BRL]: {
    code: Currency.BRL,
    fullName: 'Brazilian Real',
    currencySymbol: CurrencySymbol.BRL,
  },
  [Currency.BSD]: {
    code: Currency.BSD,
    fullName: 'Bahamian Dollar',
    currencySymbol: CurrencySymbol.BSD,
  },
  [Currency.BTN]: {
    code: Currency.BTN,
    fullName: 'Bhutanese Ngultrum',
    currencySymbol: CurrencySymbol.BTN,
  },
  [Currency.BWP]: {
    code: Currency.BWP,
    fullName: 'Botswana Pula',
    currencySymbol: CurrencySymbol.BWP,
  },
  [Currency.CAD]: {
    code: Currency.CAD,
    fullName: 'Canadian Dollar',
    currencySymbol: CurrencySymbol.CAD,
  },
  [Currency.CHF]: {
    code: Currency.CHF,
    fullName: 'Swiss Franc',
    currencySymbol: CurrencySymbol.CHF,
  },
  [Currency.CLP]: {
    code: Currency.CLP,
    fullName: 'Chilean Peso',
    currencySymbol: CurrencySymbol.CLP,
  },
  [Currency.COP]: {
    code: Currency.COP,
    fullName: 'Colombian Peso',
    currencySymbol: CurrencySymbol.COP,
  },
  [Currency.CRC]: {
    code: Currency.CRC,
    fullName: 'Costa Rican Colón',
    currencySymbol: CurrencySymbol.CRC,
  },
  [Currency.CZK]: {
    code: Currency.CZK,
    fullName: 'Czech Koruna',
    currencySymbol: CurrencySymbol.CZK,
  },
  [Currency.DKK]: {
    code: Currency.DKK,
    fullName: 'Danish Krone',
    currencySymbol: CurrencySymbol.DKK,
  },
  [Currency.DOP]: {
    code: Currency.DOP,
    fullName: 'Dominican Peso',
    currencySymbol: CurrencySymbol.DOP,
  },
  [Currency.DZD]: {
    code: Currency.DZD,
    fullName: 'Algerian Dinar',
    currencySymbol: CurrencySymbol.DZD,
  },
  [Currency.EGP]: {
    code: Currency.EGP,
    fullName: 'Egyptian Pound',
    currencySymbol: CurrencySymbol.EGP,
  },
  [Currency.ETB]: {
    code: Currency.ETB,
    fullName: 'Ethiopian Birr',
    currencySymbol: CurrencySymbol.ETB,
  },
  [Currency.EUR]: {
    code: Currency.EUR,
    fullName: 'Euro',
    currencySymbol: CurrencySymbol.EUR,
  },
  [Currency.GBP]: {
    code: Currency.GBP,
    fullName: 'British Pound Sterling',
    currencySymbol: CurrencySymbol.GBP,
  },
  [Currency.GHS]: {
    code: Currency.GHS,
    fullName: 'Ghanaian Cedi',
    currencySymbol: CurrencySymbol.GHS,
  },
  [Currency.GMD]: {
    code: Currency.GMD,
    fullName: 'Gambian Dalasi',
    currencySymbol: CurrencySymbol.GMD,
  },
  [Currency.GTQ]: {
    code: Currency.GTQ,
    fullName: 'Guatemalan Quetzal',
    currencySymbol: CurrencySymbol.GTQ,
  },
  [Currency.GYD]: {
    code: Currency.GYD,
    fullName: 'Guyanese Dollar',
    currencySymbol: CurrencySymbol.GYD,
  },
  [Currency.HKD]: {
    code: Currency.HKD,
    fullName: 'Hong Kong Dollar',
    currencySymbol: CurrencySymbol.HKD,
  },
  [Currency.HUF]: {
    code: Currency.HUF,
    fullName: 'Hungarian Forint',
    currencySymbol: CurrencySymbol.HUF,
  },
  [Currency.IDR]: {
    code: Currency.IDR,
    fullName: 'Indonesian Rupiah',
    currencySymbol: CurrencySymbol.IDR,
  },
  [Currency.ILS]: {
    code: Currency.ILS,
    fullName: 'Israeli New Shekel',
    currencySymbol: CurrencySymbol.ILS,
  },
  [Currency.INR]: {
    code: Currency.INR,
    fullName: 'Indian Rupee',
    currencySymbol: CurrencySymbol.INR,
  },
  [Currency.JMD]: {
    code: Currency.JMD,
    fullName: 'Jamaican Dollar',
    currencySymbol: CurrencySymbol.JMD,
  },
  [Currency.JOD]: {
    code: Currency.JOD,
    fullName: 'Jordanian Dinar',
    currencySymbol: CurrencySymbol.JOD,
  },
  [Currency.JPY]: {
    code: Currency.JPY,
    fullName: 'Japanese Yen',
    currencySymbol: CurrencySymbol.JPY,
  },
  [Currency.KES]: {
    code: Currency.KES,
    fullName: 'Kenyan Shilling',
    currencySymbol: CurrencySymbol.KES,
  },
  [Currency.KHR]: {
    code: Currency.KHR,
    fullName: 'Cambodian Riel',
    currencySymbol: CurrencySymbol.KHR,
  },
  [Currency.KRW]: {
    code: Currency.KRW,
    fullName: 'South Korean Won',
    currencySymbol: CurrencySymbol.KRW,
  },
  [Currency.KWD]: {
    code: Currency.KWD,
    fullName: 'Kuwaiti Dinar',
    currencySymbol: CurrencySymbol.KWD,
  },
  [Currency.KZT]: {
    code: Currency.KZT,
    fullName: 'Kazakhstani Tenge',
    currencySymbol: CurrencySymbol.KZT,
  },
  [Currency.LAK]: {
    code: Currency.LAK,
    fullName: 'Lao Kip',
    currencySymbol: CurrencySymbol.LAK,
  },
  [Currency.LKR]: {
    code: Currency.LKR,
    fullName: 'Sri Lankan Rupee',
    currencySymbol: CurrencySymbol.LKR,
  },
  [Currency.MAD]: {
    code: Currency.MAD,
    fullName: 'Moroccan Dirham',
    currencySymbol: CurrencySymbol.MAD,
  },
  [Currency.MDL]: {
    code: Currency.MDL,
    fullName: 'Moldovan Leu',
    currencySymbol: CurrencySymbol.MDL,
  },
  [Currency.MGA]: {
    code: Currency.MGA,
    fullName: 'Malagasy Ariary',
    currencySymbol: CurrencySymbol.MGA,
  },
  [Currency.MKD]: {
    code: Currency.MKD,
    fullName: 'Macedonian Denar',
    currencySymbol: CurrencySymbol.MKD,
  },
  [Currency.MNT]: {
    code: Currency.MNT,
    fullName: 'Mongolian Tögrög',
    currencySymbol: CurrencySymbol.MNT,
  },
  [Currency.MOP]: {
    code: Currency.MOP,
    fullName: 'Macanese Pataca',
    currencySymbol: CurrencySymbol.MOP,
  },
  [Currency.MUR]: {
    code: Currency.MUR,
    fullName: 'Mauritian Rupee',
    currencySymbol: CurrencySymbol.MUR,
  },
  [Currency.MXN]: {
    code: Currency.MXN,
    fullName: 'Mexican Peso',
    currencySymbol: CurrencySymbol.MXN,
  },
  [Currency.MYR]: {
    code: Currency.MYR,
    fullName: 'Malaysian Ringgit',
    currencySymbol: CurrencySymbol.MYR,
  },
  [Currency.MZN]: {
    code: Currency.MZN,
    fullName: 'Mozambican Metical',
    currencySymbol: CurrencySymbol.MZN,
  },
  [Currency.NAD]: {
    code: Currency.NAD,
    fullName: 'Namibian Dollar',
    currencySymbol: CurrencySymbol.NAD,
  },
  [Currency.NGN]: {
    code: Currency.NGN,
    fullName: 'Nigerian Naira',
    currencySymbol: CurrencySymbol.NGN,
  },
  [Currency.NOK]: {
    code: Currency.NOK,
    fullName: 'Norwegian Krone',
    currencySymbol: CurrencySymbol.NOK,
  },
  [Currency.NZD]: {
    code: Currency.NZD,
    fullName: 'New Zealand Dollar',
    currencySymbol: CurrencySymbol.NZD,
  },
  [Currency.OMR]: {
    code: Currency.OMR,
    fullName: 'Omani Rial',
    currencySymbol: CurrencySymbol.OMR,
  },
  [Currency.PEN]: {
    code: Currency.PEN,
    fullName: 'Peruvian Sol',
    currencySymbol: CurrencySymbol.PEN,
  },
  [Currency.PHP]: {
    code: Currency.PHP,
    fullName: 'Philippine Peso',
    currencySymbol: CurrencySymbol.PHP,
  },
  [Currency.PKR]: {
    code: Currency.PKR,
    fullName: 'Pakistani Rupee',
    currencySymbol: CurrencySymbol.PKR,
  },
  [Currency.PLN]: {
    code: Currency.PLN,
    fullName: 'Polish Złoty',
    currencySymbol: CurrencySymbol.PLN,
  },
  [Currency.PYG]: {
    code: Currency.PYG,
    fullName: 'Paraguayan Guarani',
    currencySymbol: CurrencySymbol.PYG,
  },
  [Currency.QAR]: {
    code: Currency.QAR,
    fullName: 'Qatari Riyal',
    currencySymbol: CurrencySymbol.QAR,
  },
  [Currency.RON]: {
    code: Currency.RON,
    fullName: 'Romanian Leu',
    currencySymbol: CurrencySymbol.RON,
  },
  [Currency.RSD]: {
    code: Currency.RSD,
    fullName: 'Serbian Dinar',
    currencySymbol: CurrencySymbol.RSD,
  },
  [Currency.RWF]: {
    code: Currency.RWF,
    fullName: 'Rwandan Franc',
    currencySymbol: CurrencySymbol.RWF,
  },
  [Currency.SAR]: {
    code: Currency.SAR,
    fullName: 'Saudi Riyal',
    currencySymbol: CurrencySymbol.SAR,
  },
  [Currency.SEK]: {
    code: Currency.SEK,
    fullName: 'Swedish Krona',
    currencySymbol: CurrencySymbol.SEK,
  },
  [Currency.SGD]: {
    code: Currency.SGD,
    fullName: 'Singapore Dollar',
    currencySymbol: CurrencySymbol.SGD,
  },
  [Currency.THB]: {
    code: Currency.THB,
    fullName: 'Thai Baht',
    currencySymbol: CurrencySymbol.THB,
  },
  [Currency.TND]: {
    code: Currency.TND,
    fullName: 'Tunisian Dinar',
    currencySymbol: CurrencySymbol.TND,
  },
  [Currency.TRY]: {
    code: Currency.TRY,
    fullName: 'Turkish Lira',
    currencySymbol: CurrencySymbol.TRY,
  },
  [Currency.TTD]: {
    code: Currency.TTD,
    fullName: 'Trinidad and Tobago Dollar',
    currencySymbol: CurrencySymbol.TTD,
  },
  [Currency.TWD]: {
    code: Currency.TWD,
    fullName: 'New Taiwan Dollar',
    currencySymbol: CurrencySymbol.TWD,
  },
  [Currency.TZS]: {
    code: Currency.TZS,
    fullName: 'Tanzanian Shilling',
    currencySymbol: CurrencySymbol.TZS,
  },
  [Currency.USD]: {
    code: Currency.USD,
    fullName: 'United States Dollar',
    currencySymbol: CurrencySymbol.USD,
  },
  [Currency.UYU]: {
    code: Currency.UYU,
    fullName: 'Uruguayan Peso',
    currencySymbol: CurrencySymbol.UYU,
  },
  [Currency.UZS]: {
    code: Currency.UZS,
    fullName: 'Uzbekistani So',
    currencySymbol: CurrencySymbol.UZS,
  },
  [Currency.VND]: {
    code: Currency.VND,
    fullName: 'Vietnamese Đồng',
    currencySymbol: CurrencySymbol.VND,
  },
  [Currency.XAF]: {
    code: Currency.XAF,
    fullName: 'Central African CFA Franc',
    currencySymbol: CurrencySymbol.XAF,
  },
  [Currency.XCD]: {
    code: Currency.XCD,
    fullName: 'East Caribbean Dollar',
    currencySymbol: CurrencySymbol.XCD,
  },
  [Currency.XOF]: {
    code: Currency.XOF,
    fullName: 'West African CFA Franc',
    currencySymbol: CurrencySymbol.XOF,
  },
  [Currency.ZAR]: {
    code: Currency.ZAR,
    fullName: 'South African Rand',
    currencySymbol: CurrencySymbol.ZAR,
  },
};

export const AVAILABLE_INTEGRATIONS = {
  OPERA: 'opera',
  STAYNTOUCH: 'stayntouch',
  INFOR: 'infor',
};

export const BRAND_FEATURES = Object.values(AVAILABLE_BRAND_FEATURES);

export const PAYMENT_PLATFORMS = {
  STRIPE_US: 'stripe-us',
  STRIPE_EU: 'stripe-eu',
  KICC: 'kicc',
};

import { useDispatch } from 'AppProvider';
import {
  HYATT_ACCESS_TOKEN_EXPIRY_TIME_SESSION_KEY,
  HYATT_ACCESS_TOKEN_SESSION_KEY,
  HYATT_AUTH_REDIRECT_URL,
} from 'consts/HyattLMSConsts';
import { useToastContext } from 'ToastProvider';
import { setMemberInfo } from '../reducers/rewardProgramIntegration';
import useIsHyattLMSEnabledFlag from './useIsHyattLMSEnabledFlag';

const useHyattAuth = () => {
  const dispatch = useDispatch();
  const { showToast: toast } = useToastContext();
  const { href } = new URL(window.location.href);
  const hyattLMSEnabled = useIsHyattLMSEnabledFlag();

  const handleSignOut = () => {
    sessionStorage.removeItem(HYATT_ACCESS_TOKEN_SESSION_KEY);
    sessionStorage.removeItem(HYATT_ACCESS_TOKEN_EXPIRY_TIME_SESSION_KEY);
    toast({
      type: 'success',
      title: 'Sign out successful',
    });
  };

  const removeCodeFromURL = () => {
    const currentUrl = new URL(href);
    currentUrl.searchParams.delete('code');
    window.history.replaceState({}, '', currentUrl.toString());
  };

  const getMemberInfo = async (token: string) => {
    const response = await fetch(
      `${process.env.KOUTO_API_URL}/v2/hyatt/member/profile`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      },
    );
    if (!response.ok) {
      const errorDetails = await response.json();
      throw new Error(
        `Error fetching member info: ${
          errorDetails.message || 'Unknown error'
        }`,
      );
    }
    const data = await response.json();
    dispatch(setMemberInfo(data.data));
    return data.data;
  };

  const setHyattAccessToken = (accessToken: string, expiresIn: number) => {
    const expiryTime = Date.now() + expiresIn * 1000;
    sessionStorage.setItem(HYATT_ACCESS_TOKEN_SESSION_KEY, accessToken);
    sessionStorage.setItem(
      HYATT_ACCESS_TOKEN_EXPIRY_TIME_SESSION_KEY,
      expiryTime.toString(),
    );
  };

  const postAuthenticate = async (authCode: string) => {
    try {
      const body = {
        code: authCode,
        redirectUrl: HYATT_AUTH_REDIRECT_URL,
      };
      const response = await fetch(
        `${process.env.KOUTO_API_URL}/v2/hyatt/authenticate`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
      );

      const data = await response.json();
      const { accessToken } = data.data;
      const { expiresIn } = data.data;
      setHyattAccessToken(accessToken, expiresIn);
      removeCodeFromURL();
      toast({
        type: 'success',
        title: 'Sign in successful',
      });
    } catch (error) {
      toast({
        type: 'failure',
        title: 'Sign in failed',
      });
    }
  };

  const getCodeFromURL = (url: string) => {
    const parsedUrl = new URL(url);
    const params = new URLSearchParams(parsedUrl.search);
    return params.get('code');
  };

  const checkForAuthCode = () => {
    if (!hyattLMSEnabled) return null;
    const code = getCodeFromURL(href);
    if (!code) return null;
    return code;
  };

  const validateToken = () => {
    const expiryTime = sessionStorage.getItem(
      HYATT_ACCESS_TOKEN_EXPIRY_TIME_SESSION_KEY,
    );
    if (!expiryTime) {
      return false;
    }
    if (Date.now() > Number(expiryTime)) {
      return false;
    }
    return true;
  };

  const getAccessToken = () => {
    if (validateToken()) {
      return sessionStorage.getItem(HYATT_ACCESS_TOKEN_SESSION_KEY);
    }
    return null;
  };

  const verifySession = () => {
    if (!hyattLMSEnabled) return false;
    const accessToken = getAccessToken();
    return !!accessToken;
  };

  return {
    checkForAuthCode,
    verifySession,
    getAccessToken,
    getMemberInfo,
    postAuthenticate,
    handleSignOut,
  };
};

export default useHyattAuth;

import { withErrorBoundary } from 'components/ErrorBoundary/withErrorBoundary';
import { A } from 'components/Link/A';
import { SkeletonLine } from 'components/theme/Skeleton/SkeletonLine';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ILocation } from 'types/experience.types';
import { ExperiencesBodyMap, Title4, Paragraph } from './styles';

interface IExperienceLocationProps extends JSX.IntrinsicAttributes {
  location?: ILocation;
  loading: boolean;
}

const ExperienceLocationComponent: React.FC<IExperienceLocationProps> = ({
  location,
  loading,
}) => {
  const { t: translate } = useTranslation();

  if (!location) return null;

  if (loading) {
    return (
      <ExperiencesBodyMap>
        <Title4>
          <SkeletonLine translucent />
        </Title4>
        <Paragraph>
          <SkeletonLine translucent />
        </Paragraph>
        <Paragraph>
          <SkeletonLine translucent />
        </Paragraph>
      </ExperiencesBodyMap>
    );
  }

  const { name, additionalInfo } = location;

  return (
    <ExperiencesBodyMap>
      <Title4 className="experience-view__experience-section-title">
        {translate('location')}
      </Title4>
      {additionalInfo && <Paragraph>{additionalInfo}</Paragraph>}
      <Paragraph>
        <A
          href={`https://www.google.com/maps/search/?api=1&query=${name}`}
          target="_blank"
        >
          {name}
        </A>
      </Paragraph>
    </ExperiencesBodyMap>
  );
};

export const ExperienceLocation = withErrorBoundary<IExperienceLocationProps>(
  ExperienceLocationComponent,
);
